import { Input } from 'antd-mobile'
import { COINS_PRICE } from '../../constants'
import { toFixed, isNumber } from '../../utils'

import styles from "./index.module.scss";

const WithdrawModal = ({withdraw, close, ...props}) => {

  const getUsdt = (amount) => {
    const _gas = 30

    if (!isNumber(amount) || amount-_gas<=0) {
      return 0
    }

    return toFixed((parseFloat(amount)-_gas))
  }
  return (<div className={styles['modal-box']}>
    <div onClick={close} className={styles['close']}></div>
    <div className={styles['title']}>Withdraw</div>
    <div className={styles['input-box']}>
      <div className={styles['input-title']}>withdraw amount</div>
      <div className={styles['input']}>
        <Input
          type="text"
          placeholder='0'
          style={{ '--text-align': 'center', '--font-size': '25px' }}
          {...props}
        />
      </div>
      <div style={{marginBottom: '20px'}} className={styles['pay-info']}>
        <div className={styles['ton-box']}>
          <div className={styles['ton']}>You can get ≈ <span>{getUsdt(props.value)}</span> NOT</div>
        </div>
      </div>
      <div className={styles['hint']}>Minimum withdrawal 50 NOT, maximum withdrawal 20000 NOT.</div>
      <div className={styles['hint']}>Each withdrawal requires 30 NOT as gas.</div>
    </div>
    <div
      onClick={withdraw}
      className={styles['deposit-confirm']}>Withdraw</div>
  </div>)
}

export default WithdrawModal