import { post, get, postNoToken } from './http';
// 获取token
export const getToken = async data => {
  try {
    const res = await post('/user/sign_login', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
  
}

// chain list
export const chainList = async () => {
  try {
    const res = get('/token/chain_list')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}


// 注册 暂时不用
export const getRegister = async () => {
  try {
    const res = get('/user/register')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 登录 进入游戏调登录 如果有 后端直接返回用户信息 如果没有 后端注册后返回登录信息
export const login = async (data) => {
  try {
    const res = postNoToken('/user/login', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 获取用户基本信息
export const userInfo = async () => {
  try {
    const res = get('/user/info')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 获取游戏基本信息 场次id 各个区间时间等
export const getGameInfo = async () => {
  try {
    const res = get('/game/info')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 获取游戏参加游戏人信息
export const getStakeUsers = async (roomId) => {
  try {
    // const res = post(`/game/stake-users`, {room_id: roomId})

    // test
    const res = post(`/game/stake-users`, {room_id: roomId})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 下注
export const stake = async (data) => {
  try {
    const res = post('/game/stake', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 充值
export const deposit = async (data) => {
  try {
    const res = post('/game/deposit', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 提现
export const withdraw = async (data) => {
  try {
    const res = post('/game/withdraw', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 个人历史记录
export const stakeHistory = async (page = 1, pageSize = 100) => {
  try {
    const res = post('/user/stake-history', {
      page: page,
      page_size: pageSize
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 昨日排行
export const yesterdayRanking = async () => {
  try {
    const res = post('/game/yesterday-ranking')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// TON 报价
export const tonPrice = async () => {
  try {
    const res = get('/game/ton-price')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 个人积分明细
export const scoreHistory = async () => {
  try {
    const res = post('/user/score-history')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 积分排行榜
export const scoreRanking = async (page=1, pageSize=100) => {
  try {
    const res = post('/game/score-ranking',{
      page: page,
      page_size: pageSize
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 邀请列表
export const invitations = async (page=1, pageSize=100) => {
  try {
    const res = get('/user/invitations', {page:1, page_size: 1000})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}


export const createInviteLink = async (id) => {
  try {
    const res = post('/b/create-invite-link',{
      tg_user_id: id
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 每日任务签到 列表和签到
export const dailyTasks = async (id) => {
  try {
    const res = get('/user/daily-tasks')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 任务列表
export const taskList = async (id) => {
  try {
    const res = get('/user/timed-tasks')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 完成任务检查并发放积分
export const checkTask = async (data) => {
  try {
    const res = post('/user/check-task', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 广告任务
export const adTask = async () => {
  try {
    const res = get('/user/image-tasks')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 保存钱包地址
export const saveAddress = async (address) => {
  try {
    const res = post('/wallet/save-address', {wallet_address: address})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 完成广告任务发放奖励
export const finishAdTask = async (adId = 1) => {
  try {
    const res = post('/user/finish-ad-task', {ad_id: adId})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 荣誉任务列表
export const honorList = async (timeType) => {
  try {
    const res = post('/user/honor-list', {time_type: timeType, page: 1, page_size:100})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 领取任务奖励
export const honorReward = async (taskId) => {
  try {
    const res = post('/user/honor-reward', {user_task_id: taskId})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 领取所有任务奖励
export const honorFinish = async (timeType) => {
  try {
    const res = post('/user/honor-finish', {time_type: timeType})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 完成外部任务的回调
export const honorHook = async (taskId) => {
  try {
    const res = post('/user/honor-hook', {task_id: taskId})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 
export const digTask = async () => {
  try {
    const res = post('/user/dig-task')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}
