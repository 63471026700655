const ENV = process.env.REACT_APP_ENV

const config = {
  test: {
    base_url: 'https://noton.be.test.derbygames.link',
    base_ws: 'wss://noton.be.test.derbygames.link',
    miniapp_link: 'https://t.me/BetaNotonBot/game',
    group_link: 'https://t.me/noton_tap_community',
    web_url: 'https://betbtc.metaderby.fe.test.dbytothemoon.com',
    // base_url: 'https://fb.api.derbygames.link',
    // base_ws: 'wss://fb.api.derbygames.link',
    deposit_address: 'UQDpgq1wmj2AsJlpi23HuGr2T3iNujmEKdOKmYyqmBRAD5D-'
    // deposit_address: 'UQCF0GkjDpK8AvTwMNw7l9YO1Xghkd2eUPi2c0eRGaVcPs5W'
  },
  prod: {
    base_url: 'https://noton.api.derbygames.link',
    base_ws: 'wss://noton.api.derbygames.link',
    miniapp_link: 'https://t.me/NotonOffice_bot/game',
    group_link: 'https://t.me/noton_tap_community',
    web_url: 'https://noton.derbygames.link',
    deposit_address: 'UQCF0GkjDpK8AvTwMNw7l9YO1Xghkd2eUPi2c0eRGaVcPs5W'
  }
}

const CONFIG = {
  BASE_URL: config[ENV].base_url,
  BASE_WS: config[ENV].base_ws,
  MINIAPP_LINK: config[ENV].miniapp_link,
  GROUP_LINK: config[ENV].group_link,
  WEB_URL: config[ENV].web_url,
  DEPOSIT_ADDRESS: config[ENV].deposit_address,
}

export default CONFIG