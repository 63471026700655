import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import Tab from '../../layout/Tab'
import Link from "../../components/Link"
import GameInfo from "../../components/GameInfo"
import styles from './index.module.scss'
import {useNavigate} from 'react-router-dom'
import { on } from '@tma.js/sdk';
import LINE_1 from '../../assets/images/a/line_1_icon.png'
import LINE_2 from '../../assets/images/a/line_2_icon.png'
import LOGO from '../../assets/images/a/not_coins_2.png'
import HISTORY from '../../assets/images/history_icon.png'
import STAR from '../../assets/images/star_icon.png'
import { tournamentInfo, rank, isJoin } from '../../utils/gameApi'

let intervalId;

const Vs = () => {

  const [show, setShow] = useState(true)
  const [rankList, setRankList] = useState([])
  const [detail, setDetail] = useState({})
  const [time, setTime] = useState('00:00')
  const [self, setSelf] = useState({
    ranking: '',
    name: '',
    point: 0,
    reward: 0
  })

  const navigate = useNavigate()

  useEffect(() => {
    joins()
    getInfo()
  }, [])

  useEffect(() => {
    const removeBack =  on('back_button_pressed', () => {
      console.log('earn')
      navigate(-10000)
    });
    return () => {
      removeBack()
    }
  }, [])

  const {
    user
  } = useSelector((state) => state.user);

  const countdown = (seconds) => {
    // const hours = Math.floor(seconds / 3600);
    intervalId = setInterval(() => {
      var days = Math.floor(seconds / (3600*24));
      const _day = seconds % (3600*24);
  
      var hrs = Math.floor(_day / 3600);
      const _hrs = _day % 3600;
  
      var mins = Math.floor(_hrs / 60);
      const _mins = _hrs % 60;
  
      // 补零处理
      hrs = hrs < 10 ? '0' + hrs : hrs;
      mins = mins < 10 ? '0' + mins : mins;
      const s = _mins < 10 ? '0' + _mins : _mins;
      const text = days>0 ? days + " days " + hrs + ":" + mins + ":" + s : hrs + ":" + mins + ":" + s
      if (seconds > 0) {
        setTime(text);
        seconds--;
      } else {
        setTime('End');
        clearInterval(intervalId);
      }
    }, 1000);
  }

  const getInfo = async () => {
    const res = await tournamentInfo()
    if (res.code !== 0) {
      return
    }
    setDetail(res.data)
    
    const {id, remain_time} = res.data
    countdown(remain_time)
    const _rank = await rank(id)
    if (_rank.code !== 0) {
      return
    }
    setRankList(_rank.data)
    const _self = _rank.data.find(x => {
      return x.user_id === user.id
    })
    if (_self) {
      setSelf({
        ranking: _self.rank,
        name: _self.username,
        point: _self.Score,
        reward: _self.reward
      })
    }
  }

  const joins = async () => {
    const res = await isJoin()
    if (res.code !== 0) {
      return
    }
    
    console.log('join', res)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  return (
    <div className={styles["vs-container"]}>
      <div className={styles["line-box"]}>
        <img src={LINE_1} alt="" />
        <div className={styles['text']}>Competition</div>
      </div>
      <div onClick={handleShow} className={styles['detail']}>
        <div className={styles['bg']}></div>
        <div className={styles['info']}>
          <div className={styles['item']}>
            <div className={styles['item-title']}>Prize pool up to</div>
            <div className={styles['item-num']}>
              <img src={LOGO} alt="icon" />
              {detail.prize_pool}
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['item-title']}>Entry fee</div>
            <div className={styles['item-num']}>
              <img src={STAR} alt="icon" />
              {detail.entry_fee}
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['item-title']}>Time left</div>
            <div className={styles['item-num']}>{time}</div>
          </div>
        </div>
      </div>
      <div className={styles["line-box"]}>
        <img src={LINE_2} alt="" />
        <div className={styles['text']}>Current ranking</div>
        <Link to="/history">
          <img className={styles['history']} src={HISTORY} alt="icon" />
        </Link>
      </div>
      <div className={styles['ranking-box']}>
        {self.ranking && <div className={styles['self']}>
          <div className={styles['info-item']}>{self.ranking}</div>
          <div className={styles['info-item']}>{self.name}</div>
          <div className={styles['info-item']}>{self.point}</div>
          <div className={styles['info-item']}>
            <img src={LOGO} alt="icon" />
            {self.reward}
          </div>
        </div>}
        <div className={styles['title-box']}>
          <div className={styles['title-item']}>ranking</div>
          <div className={styles['title-item']}>name</div>
          <div className={styles['title-item']}>integral</div>
          <div className={styles['title-item']}>obtain</div>
        </div>
        {rankList.map((item, index) => {
          return (<div key={item.id} className={styles['info-box']}>
            <div className={styles['info-item']}>{index+1}</div>
            <div className={styles['info-item']}>{item.username}</div>
            <div className={styles['info-item']}>{item.Score}</div>
            <div className={styles['info-item']}>
              <img src={LOGO} alt="icon" />
              {item.reward}
            </div>
          </div>)
        })}
      </div>
      {show && <GameInfo close={handleClose} />}
      <Tab type='2' />
    </div>
  )
}

export default Vs;
