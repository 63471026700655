export const COINS_PRICE = 0.1 // 1 USDT = 10 coins

export const ODDS = 2

export const TON_WEI = 1000000000
export const TOKEN = 'token'
export const START_MODAL = 'start'

export const ADDRESS = 'address'

export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000'


export const UNISWAP_FACTORY = '0x1F98431c8aD98523631AE4a59f267346ea31F984'

// 划点补偿，为避免后端报价过高 根据后端报价适当减一个常量当作划点
export const SLIPPAGE = 0.005

export const APPROVE_NUM = 10000000000


export const LINE_WIDTH = 0.7

// 1 2 3 4
export const TIME1 = 25000 // 下注中
export const TIME2 = 3000 // 开始前等待时间
export const TIME3 = 12000 // 比赛时间
export const TIME4 = 10000 // 发奖励

export const TEST_UP_POOL = 600
export const TEST_DOWN_POOL = 400

export const GAME_STATUS = {
  ANTE_START: 1, // 下注开始
  ANTE_IN: 2, // 下注中
  GAME_START_BEFORE: 3, // 游戏开始前等待
  GAME_START: 4, // 游戏开始
  GAME_IN: 5, // 游戏中
  GAME_END: 6, // 游戏结束
  GAME_END_PENDING: 7 // 游戏开始前等待
}

export const USER_TYPE = {
  Un_known: 0,
  Internal: 1,
  New: 2,
  Real: 3,
  Robot: 4,
  KOL: 5
}

export const LEN = 12

export const USER_OBJ = {
  user_id: '',
  avatar: '',
  amount: 0
}

export const UP_OR_DOWN_LIST = new Array(LEN).fill(USER_OBJ);

export const TEST_UP_LIST = [{
  id:1,
  name: 'james1',
  img:'https://api.dicebear.com/5.x/open-peeps/svg?seed=0xd76023ed85767f4d197f61c0db1b5193581cd89f&flip=false'
},{
  id:2,
  name: 'james2',
  img:'https://api.dicebear.com/5.x/open-peeps/svg?seed=0x5705faa448bf1222a196bf16871ee8b86ceb7761&flip=false'
},{
  id:3,
  name: 'james3',
  img:'https://api.dicebear.com/5.x/open-peeps/svg?seed=0xcbb7b3141d0413c4897ecc17e7e10929d647b143&flip=true'
}]
export const TEST_DOWN_LIST = [
  {
    id:4,
    name: 'james4',
    img:'https://api.dicebear.com/5.x/open-peeps/svg?seed=0x7e46025a734b6e76db1e9770514f62e2df16c321&flip=true'
  },{
    id:5,
    name: 'james5',
    img:'https://api.dicebear.com/5.x/open-peeps/svg?seed=0xd399b2eaebd4d4ff65a5fa2748595684e9e9120a&flip=true'
  }
]


export const LEVEL_TYPE = {
  1: 0.001,
  2: 0.002,
  3: 0.003,
  4: 0.004,
  5: 0.005,
  6: 0.006,
  7: 0.007,
  8: 0.008,
  9: 0.009,
  10: 0.01,
  11: 0.011,
  12: 0.012,
  13: 0.013,
  14: 0.014,
  15: 0.015,
  16: 0.016,
  17: 0.017,
  18: 0.018,
  19: 0.019,
  20: 0.02,
  21: 0.021,
  22: 0.022,
  23: 0.023,
  24: 0.024,
  25: 0.025,
  26: 0.026,
  27: 0.027,
  28: 0.028,
  29: 0.029,
  30: 0.03,
}

// TEST WEB
export const TEST_ENV = 'tg'  // web | tg
export const ENV = {
  WEB: 'web',
  TG: 'tg'
}

export const testInitData = "user=%7B%22id%22%3A1499574699%2C%22first_name%22%3A%22Wake%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22Wake_Zhao%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=6882760630751711234&chat_type=private&start_param=238d2d31d3d3b68016958033b30d3745b07b34a8416ee76d6b1459955c&auth_date=1731113545&hash=214a0d564c4beedfb0165a50105f33de617fc629a61b95856919c8648c46f607"