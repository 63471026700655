import styles from './index.module.scss'
import { useState, useEffect } from 'react'
import COINS_ICON from '../../assets/images/a/not_coins.png'


const getRandom = (min = 3, max = 16) => {
  min = Math.ceil(min); // 确保min是整数
  max = Math.floor(max); // 确保max是整数
  return Math.floor(Math.random() * (max - min + 1)) + min; // 返回介于min和max之间的整数
}

const DropCoins = ({gameStatus, setDropNotFn}) => {
  const width = Math.abs(document.body.offsetWidth - 70)
  const redEnvelopeList = [{
    id: 1,
    left: `${Math.random() * width}px`,
    duration: `${Math.random() * 3 + 3}s`,
    show: true,
    delay: `${getRandom()}s`
  },{
    id: 2,
    left: `${Math.random() * width}px`,
    duration: `${Math.random() * 3 + 3}s`,
    show: true,
    delay: `${getRandom()}s`
  },{
    id: 3,
    left: `${Math.random() * width}px`,
    duration: `${Math.random() * 3 + 3}s`,
    show: true,
    delay: `${getRandom()}s`
  },{
    id: 4,
    left: `${Math.random() * width}px`,
    duration: `${Math.random() * 3 + 3}s`,
    show: true,
    delay: `${getRandom()}s`
  },{
    id: 5,
    left: `${Math.random() * width}px`,
    duration: `${Math.random() * 3 + 3}s`,
    show: true,
    delay: `${getRandom()}s`
  }]

    const [hhhh, setHhhh] = useState(redEnvelopeList)

    useEffect(() => {
      console.log('===========gameStatus==========', gameStatus)
      if (gameStatus === '2') {
        setHhhh(redEnvelopeList)
      }
    }, [gameStatus]);

    const handleDelete = (item) => {
      const _hhhh = hhhh.map(x => {
        return {
          ...x,
          show: item.id === x.id ? false : x.show
        }
      })
      setDropNotFn()
      console.log('_hhhh', _hhhh)
      setHhhh(_hhhh)
    }

    return (
      <>
        {
          hhhh.map((item) => {
            return (
              <div
                className={`${styles['img']}  ${gameStatus === '1' ? styles['drop'] : ''}`}
                style={{
                  left: item.left,
                  animationDuration: item.duration,
                  animationDelay: item.delay,
                  animationPlayState: !item.show ? 'paused': ''
                }}
                key={item.id}
              >
                {item.show && <img
                  src={COINS_ICON}
                  onClick={() => handleDelete(item)}
                />}
                {!item.show && <div className={styles['not-text']}>+1 NOT</div>}
              </div>
            );
          })
        }
      </>
    );
};

export default DropCoins;


 
// const CoinCollector = () => {
//   const [coinCount, setCoinCount] = useState(0);
//   const coinContainerRef = useRef(null);
 
//   // 随机生成金币的函数
//   const dropCoins = () => {
//     for (let i = 0; i < 5; i++) {
//       const coin = document.createElement('div');
//       coin.classList.add(styles["coin"]);
//       coinContainerRef.current.appendChild(coin);
 
//       // 随机位置
//       const top = Math.random() * window.innerHeight;
//       const left = Math.random() * window.innerWidth;
//       coin.style.top = `${top}px`;
//       coin.style.left = `${left}px`;
 
//       // 随机下落速度
//       const fallDuration = Math.random() * 20 + 10; // 10s ~ 30s
//       coin.style.animation = `fall ${fallDuration}s linear`;
 
//       // 点击金币的事件监听
//       coin.addEventListener('click', () => {
//         coin.remove(); // 移除金币元素
//         setCoinCount(coinCount + 1); // 更新金币数量
//       });
//     }
//   };
 
//   // 20秒后开始随机生成金币
//   useEffect(() => {
//     setTimeout(dropCoins, 2000);
//   }, []);
 
//   return (
//     <div>
//       <div ref={coinContainerRef} className={styles["coin-container"]}></div>
//       <div className={styles["score"]}>Score: {coinCount}</div>
//     </div>
//   );
// };

