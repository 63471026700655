import React from "react";
import styles from './index.module.scss'

const MainButton = ({padding="8px 16px", disable, children, ...props}) => {
  return (
    <button {...props} className={`${styles['button-container']} ${disable ? styles['disable'] : ''}`}>
      <div style={{
        padding: padding
      }} className={styles['btn-bg']}>
        {children}
      </div>
    </button>
  )
}

export default MainButton;