import { useEffect, useState } from "react";
import { useSelector , useDispatch } from "react-redux";
import { userSlice } from "../../../../redux/user/slice";
import { buildArray } from "../../../../utils"
import { taskReward, winningTask } from "../../../../utils/gameApi"
import MyToast from "../../../../components/MyToast"

import DONE from '../../../../assets/images/task_done.png'
import UNDO from '../../../../assets/images/task_undo.png'

import BOX_10_OFF from '../../../../assets/images/box0_off.png'
import BOX_10_ON from '../../../../assets/images/box0_on.png'

import BOX_20_OFF from '../../../../assets/images/box1_off.png'
import BOX_20_ON from '../../../../assets/images/box1_on.png'

import BOX_50_OFF from '../../../../assets/images/box2_off.png'
import BOX_50_ON from '../../../../assets/images/box2_on.png'

import BOX_100_OFF from '../../../../assets/images/box3_off.png'
import BOX_100_ON from '../../../../assets/images/box3_on.png'

import BOX_200_OFF from '../../../../assets/images/box4_off.png'
import BOX_200_ON from '../../../../assets/images/box4_on.png'

import BOX_500_OFF from '../../../../assets/images/box5_off.png'
import BOX_500_ON from '../../../../assets/images/box5_on.png'
import ARROW from '../../../../assets/images/arrow_right_icon.png'

import styles from './index.module.scss'

const defaultArr = buildArray(5, 0)

const IMAGE  = {
  BOX_10_OFF: BOX_10_OFF,
  BOX_10_ON: BOX_10_ON,
  BOX_20_OFF: BOX_20_OFF,
  BOX_20_ON: BOX_20_ON,
  BOX_50_OFF: BOX_50_OFF,
  BOX_50_ON: BOX_50_ON,
  BOX_100_OFF: BOX_100_OFF,
  BOX_100_ON: BOX_100_ON,
  BOX_200_OFF: BOX_200_OFF,
  BOX_200_ON: BOX_200_ON,
  BOX_500_OFF: BOX_500_OFF,
  BOX_500_ON: BOX_500_ON,
}

const WinningStreak = () => {
  const [taskInfo, setTaskInfo] = useState({
    id: 0,
    times: 0,
    status: 1,
    award: 20,
    description: 10
  })
  const [list, setList] = useState(defaultArr)

  const {
    taskList,
    betAmount
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const _data = taskList.find(item => {
      return item.description == betAmount
    })
    if (!_data) {
      return
    }
    setTaskInfo(_data)
    setList(buildArray(5, _data.times))
  }, [betAmount, taskList])

  const handleOpenModal = () => {

    dispatch(
      userSlice.actions.setShowTaskList({
        showTaskList: true
      })
    )
  }

  const openAward = () => {
    dispatch(
      userSlice.actions.setShowAward({
        showAward: true
      })
    )
  }

  const handleAward = async (e) => {
    e.stopPropagation()
    if (taskInfo.status !== 3) {
      handleOpenModal()
      return
    }
    const res = await taskReward(taskInfo.id)
    if (res.code === -40200) {
      MyToast({
        content: 'Task not completed',
        position: 'top',
      })
      return
    }
    if (res.code !== 0) {
      MyToast({
        content: 'Receive Award Failed',
        position: 'top',
      })
      return
    }
    console.log('taskNewReward====', res)
    dispatch(
      userSlice.actions.setAwardAmount({
        awardAmount: res.data.reward_amount
      })
    )
    getTask()
    openAward()
  }

  const getTask = async () => {
    const res = await winningTask()
    if (res.code !== 0) {
      return
    }
    const {type, data} = res.data
    dispatch(
      userSlice.actions.setTaskType({
        taskType: type,
      })
    );
    if (type === 1 && data.length>0) {
      dispatch(
        userSlice.actions.setNewTaskId({
          newTaskId: data[0].id,
        })
      );
      
      dispatch(
        userSlice.actions.setNewTaskInfo({
          newTaskStatus: data[0].status,
          newTaskNum: data[0].times,
        })
      );
      return
    }
    if (type === 2) {
      const _data = data.map(item => {
        return {
          id: item.id,
          times: item.times,
          status: item.status,
          award: (item.description)*2,
          description: item.description
        }
      })
      dispatch(
        userSlice.actions.setTaskList({
          taskList: _data
        })
      );
    }
  }

  return (
    <div className={styles["newwin-container"]}>
      <div className={styles["task-title"]}>Winning streak reward</div>
      <div onClick={handleOpenModal} className={styles["task-info"]}>
        <div className={styles["list"]}>
          <div className={styles['bar']}></div>
          {list.map((x, index) => {
            return (<img key={index} src={x===1 ? DONE : UNDO} alt="icon" />)
          })}
          
        </div>
        <div onClick={(e) =>handleAward(e)} className={styles["new-box"]}>
          {taskInfo.status === 3 && <div className={styles["dian"]}></div>}
          {taskInfo.status === 5 && <img src={IMAGE[`BOX_${taskInfo.description}_ON`]} alt="box" />}
          {taskInfo.status !== 5 && <img src={IMAGE[`BOX_${taskInfo.description}_OFF`]} alt="box" />}
        </div>
        <div className={styles["award-box"]}>
          {taskInfo.award}
        </div>
        <img src={ARROW} className={styles["arrow"]} alt="arrow" />
      </div>
    </div>
  )
}

export default WinningStreak;
