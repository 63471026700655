import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import styles from './index.module.scss'
import LINE_2 from '../../assets/images/a/line_2_icon.png'
import LOGO from '../../assets/images/a/not_coins_2.png'
import { tournamentInfo, rank } from '../../utils/gameApi'
const Ranking = () => {

  const [rankList, setRankList] = useState([])
  const [self, setSelf] = useState({
    ranking: '',
    name: '',
    point: 0,
    reward: 0
  })

  useEffect(() => {
    getInfo()
  }, [])

  const {
    user
  } = useSelector((state) => state.user);

  const getInfo = async () => {
    const res = await tournamentInfo()
    if (res.code !== 0) {
      return
    }
    const {id} = res.data
    const _rank = await rank(id)
    if (_rank.code !== 0) {
      return
    }
    setRankList(_rank.data)
    const _self = _rank.data.find(x => {
      return x.user_id === user.id
    })
    if (_self) {
      setSelf({
        ranking: _self.rank,
        name: _self.username,
        point: _self.Score,
        reward: _self.reward
      })
    }
  }

  return (
    <div className={styles["ranking-container"]}>
      <div className={styles["line-box"]}>
        <img src={LINE_2} alt="" />
        <div className={styles['text']}>Current ranking</div>
      </div>
      <div className={styles['ranking-box']}>
        <div className={styles['title-box']}>
          <div className={styles['title-item']}>ranking</div>
          <div className={styles['title-item']}>name</div>
          <div className={styles['title-item']}>integral</div>
          <div className={styles['title-item']}>obtain</div>
        </div>
        {rankList.map((item, index) => {
          return (<div key={item.id} className={styles['info-box']}>
            <div className={styles['info-item']}>{index+1}</div>
            <div className={styles['info-item']}>{item.username}</div>
            <div className={styles['info-item']}>{item.Score}</div>
            <div className={styles['info-item']}>
              <img src={LOGO} alt="icon" />
              {item.reward}
            </div>
          </div>)
        })}
        {self.ranking && <div className={styles['self']}>
          <div className={styles['info-item']}>{self.ranking}</div>
          <div className={styles['info-item']}>{self.name}</div>
          <div className={styles['info-item']}>{self.point}</div>
          <div className={styles['info-item']}>
            <img src={LOGO} alt="icon" />
            {self.reward}
          </div>
        </div>}
      </div>
    </div>
  )
}

export default Ranking;
