import IMG1 from '../../assets/images/a/not_coins.png'
import IMG2 from '../../assets/images/a/not_coins_2.png'
import styles from './index.module.scss'
import {toFixed} from '../../utils'

const PointCard = ({type, point=0, withdraw}) => {
  return (
    <div className={styles["point-card-container"]}>
      <img className={styles['logo']} src={type===1?IMG1:IMG2} alt="" />
      <div className={styles['card-point']}>{ toFixed(point, 2) }</div>
      {type===2 && <div onClick={withdraw} className={styles['withdraw']}>Withdraw</div>}
    </div>
  )
}

export default PointCard;
