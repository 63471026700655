import { useSelector } from "react-redux";

import Tab1 from '../../assets/images/a/tab1_icon.png'
import Tab2 from '../../assets/images/a/tab2_icon.png'
import Tab3 from '../../assets/images/a/tab3_icon.png'
import Tab4 from '../../assets/images/a/tab4_icon.png'
import Tab5 from '../../assets/images/a/tab5_icon.png'
import Link from "../../components/Link"
import styles from './index.module.scss'

const Tab = ({type = '1'}) => {

  const {
    showDot
  } = useSelector((state) => state.user);

  return (
    <div className={styles["tab-container"]}>
      <Link to="/" className={`${styles["tab-item"]} ${type==='3'&&styles["selected"]}`}>
        <img src={Tab3} alt="icon" className={styles['tab-img']} />
        <div className={styles['tab-text']}>
          Earn
        </div>
      </Link>
      <Link to="/vs" className={`${styles["tab-item"]} ${type==='2'&&styles["selected"]}`}>
        {showDot && <div className={styles['red']}></div>}
        <img src={Tab2} alt="icon" className={styles['tab-img']} />
        <div className={styles['tab-text']}>
          Reward
        </div>
      </Link>
      <Link to="/invite" className={`${styles["tab-item"]} ${type==='4'&&styles["selected"]}`}>
        <img src={Tab4} alt="icon" className={styles['tab-img']} />
        <div className={styles['tab-text']}>
          Invitation
        </div>
      </Link>
      <Link to="/home" className={`${styles["tab-item"]} ${type==='1'&&styles["selected"]}`}>
        <img src={Tab1} alt="icon" className={styles['tab-img']} />
        <div className={styles['tab-text']}>
          Mining
        </div>
      </Link>
      <Link to="/airdrop" className={`${styles["tab-item"]} ${type==='5'&&styles["selected"]}`}>
        <img src={Tab5} alt="icon" className={styles['tab-img']} />
        <div className={styles['tab-text']}>
          Claim
        </div>
      </Link>
    </div>
  )
}

export default Tab;
