import { animated, useSpring } from '@react-spring/web'
import { getOne } from '../../../../utils'

import styles from './index.module.scss'

const GREEN_ICON = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
      <path d="M2.29289 0.707106L0.707107 2.29289C0.0771424 2.92286 0.523309 4 1.41421 4H4.58579C5.47669 4 5.92286 2.92286 5.29289 2.29289L3.70711 0.707107C3.31658 0.316583 2.68342 0.316582 2.29289 0.707106Z" fill="#00FF57"/>
    </svg>
  )
}

const RED_ICON = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
      <path d="M3.70711 3.29289L5.29289 1.70711C5.92286 1.07714 5.47669 0 4.58579 0H1.41421C0.523309 0 0.0771408 1.07714 0.707106 1.70711L2.29289 3.29289C2.68342 3.68342 3.31658 3.68342 3.70711 3.29289Z" fill="#FF0000"/>
    </svg>
  )
}

export const UpDown = ({odd}) => {

  const type = odd >= 0 ? 1 : 2
  const _odd = `${Math.abs((odd*100).toFixed(3))}`
  return (
    <div className={styles['up-down-text']}>
      {type===1?<GREEN_ICON /> : <RED_ICON />}
      <div style={{color: type===1?'#00FF57':'#FF0000'}} className={styles['text2']}>{_odd}</div>
    </div>
  )
}

export const AddComp = ({level}) => {
  const [spring] = useSpring(() => ({
    from: { top: '-20px', fontSize: '24px', opacity: 1 },
    to: { top: '-80px', fontSize: '24px', opacity: 0 },
    config: {
      tension: 280,
      friction: 120
    }
  }),[])

  return (
    <animated.div style={spring} className={styles['click-effect']}>
      +{getOne(level)}
      <div className={styles['click-not-icon']}></div>
    </animated.div>
  )
}