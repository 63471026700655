import styles from './index.module.scss'
import { useSelector, useDispatch } from "react-redux";
import { userSlice } from "../../../../redux/user/slice";
import { GAME_STATUS } from '../../../../constants'

const { ANTE_IN, GAME_START_BEFORE, GAME_IN, GAME_END_PENDING } = GAME_STATUS

const Countdown = () => {
  const {
    status,
    timeCode
  } = useSelector((state) => state.user);
  const formatTime = (time) => {
    return Math.ceil(time/1000)
  }
  // waiting for mining
  // distrbuting payouts
  return (
    <div className={`${styles["countdown-container"]}`}>
      {status===ANTE_IN&&(<>
        <div className={styles['time-box']}>
          <div className={styles['time']}>{formatTime(timeCode)}</div>
          <div className={styles['text']}> s</div>
        </div>
        <div className={styles['hint']}>ANTE IN</div>
      </>)}
      {status===GAME_START_BEFORE&&(<>
        <div className={styles['waiting']}>WAITING<br /> FOR MINING</div>
      </>)}
      {status===GAME_IN&&(<>
        <div className={styles['time-box']}>
          <div className={styles['time']}>{formatTime(timeCode)}</div>
          <div className={styles['text']}> s</div>
        </div>
        <div className={styles['hint']}>IN GAME</div>
      </>)}
      {status===GAME_END_PENDING&&(<>
        <div className={styles['waiting']}>DISTRBUTING <br /> PAYOUTS</div>
      </>)}
    </div>
  )
}

export default Countdown;

