import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useUtils } from '@tma.js/sdk-react';
import { Modal, Mask } from 'antd-mobile'
import { userSlice } from "../../../../redux/user/slice";
import { USER_TYPE, ODDS } from "../../../../constants"
import mgif from '../../../../assets/images/R-Mgif.gif'
import GROPUP_ICON from '../../../../assets/images/group_icon.png'
import RECHARAGE_ICON from '../../../../assets/images/recharage_icon.png'
import Link from "../../../../components/Link"
import NewWin from "../NewWin"
import WinningStreak from "../WinningStreak"

import styles from './index.module.scss'

const GROUP = 'https://t.me/+4aZ2EyO_06djNTg9'

const BET_DATA = [100, 200, 400, 600, 1000]
const DATA = [{
  price: 10,
  status: false
},{
  price: 20,
  status: false
},{
  price: 50,
  status: false
},{
  price: 100,
  status: false
},{
  price: 200,
  status: false
},{
  price: 500,
  status: false
}]

const BetList = ({pay}) => {
  const [list, setList] = useState(DATA)

  const {
    betAmount,
    userType,
    user,
    newTask,
    showAward,
    showTaskList,
    taskType
  } = useSelector((state) => state.user);

  const utils = useUtils();

  const dispatch = useDispatch();

  useEffect(() => {
    const _list = BET_DATA.map(x => {
      return {
        price: x,
        status: x === betAmount
      }
    })
    console.log('_list', _list)
    setList(_list)
  }, [betAmount])

  const selectBetAmount = (item) => {
    const {price} = item
    dispatch(
      userSlice.actions.setBetAmount({
        betAmount: price,
      })
    );
  }

  const addGroup = () => {
    utils.openTelegramLink(GROUP)
  }

  const handleOpenFirst = () => {
    dispatch(
      userSlice.actions.setShowFirstDeposit({
        showFirstDeposit: true
      })
    )
  }

  const handleCloseNewTask = () => {
    dispatch(
      userSlice.actions.setNewTask({
        newTask: false
      })
    )
  }

  const handleCloseAward = () => {
    dispatch(
      userSlice.actions.setShowAward({
        showAward: false
      })
    )
  }

  const handleCloseTaskList = () => {
    dispatch(
      userSlice.actions.setShowTaskList({
        showTaskList: false
      })
    )
  }

  return (
    <div className={`${styles["betlist-container"]} step3`}>
      <div className={styles["get-amount"]}>
        {/* <div className={styles["amount-bg"]}> */}
        <div className={styles["amount-title"]}>
          <span className={styles["title1"]}>obtain</span>
          <span className={styles["title2"]}> X {parseInt(ODDS*100)}%</span>
        </div>
        {/* </div> */}
        <div className={styles["amount-text"]}>
          <span className={styles["text1"]}>{parseInt(betAmount*ODDS)}</span>
        </div>
      </div>
      {betAmount !== 0 && <div className={styles['task-box']}>
        {taskType === 1 && <NewWin />}
        {taskType === 2 && <WinningStreak />}
      </div>}
      <div className={styles["bet-icon"]}></div>
      <div className={styles["betlist-box"]}>
        <div style={{opacity: '0'}}>1</div>
        {/* <div onClick={() => selectBetAmount({price: 0, status: false})} className={`${!(betAmount===0)&&styles['bet-not-select']} ${(betAmount===0)&&styles['bet-selected']} ${styles['free']}`}><span>free</span></div> */}
        {/* <div>{JSON.stringify(list)}</div> */}
        {list.map((item) => {
          return (
            <div key={item.price} onClick={() => selectBetAmount(item)} className={`${item.status ? styles['bet-selected'] : styles['bet-not-select']}`}><span>{item.price}</span></div>
          )
        })}
      </div>

    </div>
  )
}

export default BetList;
