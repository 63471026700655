import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { deposit, createPayOrder } from '../../utils/gameApi'
import { userSlice } from "../../redux/user/slice";
import { Popup } from 'antd-mobile'
import Header from "../../layout/Header"
import CountDown from "./components/Countdown"
import BetButton from "./components/BetButton"
import BetList from "./components/BetList"
import ChartV3 from "../../components/ChartV3"
// import StartModal from "../../components/StartModal"
// import NewUserModal from "../../components/NewUserModal"
// import RejectLogin from "../../components/RejectLogin"
// import Upgrade from "../../components/Upgrade"
// import { Player2 } from "../../components/AudioPlayer"
import CONFIG from '../../config';
import { START_MODAL, TON_WEI } from '../../constants'
import MyToast from '../../components/MyToast';
import { toFixed } from "../../utils";
import PopupV2 from "./components/PopupV2"
import { useNavigate } from 'react-router-dom'

import styles from "./index.module.scss";
const { DEPOSIT_ADDRESS, CHAIN } = CONFIG

const steps = [
  {
    target: '.step1',
    content: 'BTC price trend',
    disableBeacon: true,
    hideFooter: true
  },
  {
    target: '.step2',
    content: 'Both parties UP/DOWN POOL PAYOUT',
    disableBeacon: true,
    hideFooter: true
  },
  {
    target: '.step3',
    content: 'Select amount',
    disableBeacon: true,
    hideFooter: true
  },
  {
    target: '.step4',
    content: 'Both parties bet Number of people/amount',
    disableBeacon: true,
    hideFooter: true
  },
  {
    target: '.step5',
    content: ' Select Up/Down Conduct transactions',
    disableBeacon: true,
    hideFooter: true
  }
];

const Game = () => {
  const [start, setStart] = useState(false)
  const [newUser, setNewUser] = useState(true)
  const [run, setRun] = useState(false)
  const [visible, setVisible] = useState(false)

  const userFriendlyAddress = useTonAddress()
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const {
    rejectIP,
    showFirstDeposit,
    betAmount
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    const showStart = localStorage.getItem(START_MODAL)
    if (showStart !== 'hide') {
      setStart(true)
      localStorage.setItem(START_MODAL, 'hide');
    }
  }, [])

  useEffect(() => {
    if (userFriendlyAddress) {
      setVisible(false)
    }
  }, [userFriendlyAddress])

  const handleClose = () => {
    setStart(false)
    setRun(true)
  }

  const handleCloseFirst = () => {
    dispatch(
      userSlice.actions.setShowFirstDeposit({
        showFirstDeposit: false
      })
    )
  }

  const handlePay = async (type = 'ton') => {
    if (type === 'usdt') {
      // 创建订单
      const res = await createPayOrder(1, CHAIN, 'USDT')
      if (res.code !== 0) {
        return
      }
      console.log('handlePay', res)
      navigate(`/pay/${res.data.order_no}`)
      return
    }
    console.log('handlePay')
    // 链接钱包直接支付 没链接钱包弹出链接钱包的弹窗
    if (!userFriendlyAddress) {
      setVisible(true)
      return
    }


    const res = await deposit({
      wallet_address: userFriendlyAddress,
      amount: '19',
      chain_id: 0,
      token_address: ''
    })
    if (res.code !== 0) {
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }
    const { ton_amount } = res.data
    const price = toFixed(TON_WEI*ton_amount, 0)
    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 6000,
      messages: [
        {
          address: DEPOSIT_ADDRESS, // destination address
          amount: price //Toncoin in nanotons
        }
      ]
    }
    
    tonConnectUI.sendTransaction(transaction)
    // setNewUser(false)
    handleCloseFirst()
    return
    
  }

  return (
    <div className={`${styles["home-container"]}`}>
      <Header></Header>
      <div className={`${styles["contents"]}`}>
        <CountDown />
        <div className={`${styles['chart-content']} step1`}>
          <ChartV3 />
        </div>
        <BetList pay={handlePay} />
        <BetButton />
      </div>
      {/* <NewUserModal close={handleCloseFirst} showModal={showFirstDeposit} pay={handlePay} /> */}
      {/* <StartModal showModal={start} close={handleClose} /> */}
      {/* {rejectIP && <RejectLogin />} */}
      {/* <Upgrade /> */}
      <PopupV2 visible={visible} onClose={() => {
        setVisible(false)
      }} />
    </div>);
}

export default Game;