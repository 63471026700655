import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { invitations } from '../../utils/api'
import { toFixed } from '../../utils';
import PointCard from '../../components/PointCard'
import HEADER from '../../assets/images/a/header_icon.svg'
import styles from './index.module.scss'

const Item = ({item={}}) => {
  return (
    <div className={styles['item']}>
      <img src={item.avatar} className={styles['icon']} />
      <div className={styles['name']}>{item.describe || `User_${item.id}`}</div>
      <div className={styles['point']}>+{toFixed(item.total,2)}</div>
    </div>
  )
}
const InviteDetail = () => {
  const [list, setList] = useState([])

  const {
    user
  } = useSelector((state) => state.user);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const res = await invitations()
    if (res.code !== 0) {
      return
    }
    setList(res.data)
  }
  return (
    <div className={styles["invite-detail-container"]}>
      <div className={styles['invite-title']}>My invitation</div>
      <PointCard showTitle={true} point={user.invitationReward || 0} />
      {list.length!==0 ? <div className={styles['invite-list']}>
        {list.map(item => {
          return <Item key={item.id} item={item} />
        })}
      </div> :
      <div className={styles['invite-list']}>
        <div className={styles['nodata']}>No Data</div>
      </div>}
    </div>
  )
}

export default InviteDetail;
