import Home from '../pages/home'
import Wallet from '../pages/wallet'
import Earn from '../pages/earn';
import Airdrop from '../pages/airdrop';
import Invite from '../pages/invite';
import Vs from '../pages/vs';
import Game from '../pages/game';
import InviteDetail from '../pages/inviteDetail';
import Ranking from '../pages/ranking';
import History from '../pages/history';

// const Home = React.lazy(() => import('../pages/home'))
// const Wallet = React.lazy(() => import('../pages/wallet'))
// const History = React.lazy(() => import('../pages/history'))
// const Ranking = React.lazy(() => import('../pages/ranking'))

const routes = [
  { path: '/', Component: Earn, title: 'Earn' },
  { path: '/home', Component: Home, title: 'Home' },
  { path: '/vs', Component: Vs, title: 'Vs' },
  { path: '/game', Component: Game, title: 'Game' },
  { path: '/wallet', Component: Wallet, title: 'Wallet' },
  { path: '/airdrop', Component: Airdrop, title: 'Airdrop' },
  { path: '/invite', Component: Invite, title: 'Invite' },
  { path: '/ranking', Component: Ranking, title: 'Ranking' },
  { path: '/history', Component: History, title: 'History' },
  { path: '/invite-detail', Component: InviteDetail, title: 'invite detail' },
];


export default routes