import { Input } from 'antd-mobile'
import { COINS_PRICE } from '../../constants'
import { toFixed } from '../../utils'
import styles from "./index.module.scss";
const DEPOIST_DATA = ['100', '200', '500', '1000']

const DepositModal = ({selectAmount, loadingIcon, getTonPrice, deposit, close, ton, ...props}) => {
  const {value} = props
  return (<div className={styles['modal-box']}>
    <div onClick={close} className={styles['close']}></div>
    <div className={styles['title']}>Deposit</div>
    <div className={styles['input-box']}>
      <div className={styles['input-title']}>Recharge amount</div>
      <div className={styles['input']}>
        <Input
          type="number"
          min={10}
          placeholder='0'
          style={{ '--text-align': 'center', '--font-size': '25px' }}
          clearable
          {...props}
        />
      </div>
      <div className={styles['pay-info']}>
        <div className={styles['usdt']}>={toFixed(value*COINS_PRICE, 1)} USDT</div>
        <div className={styles['ton-box']}>
          <div className={styles['ton']}>≈payment <span>{toFixed(value*COINS_PRICE/ton)}</span> TON </div>
          <>
          {!loadingIcon ? 
            <button onClick={getTonPrice} className={styles['refrash']}></button>
            :<div className={styles['loading']}></div>
          }
          </>
        </div>
       
      </div>
      <div className={styles['depoist_list']}>
        {DEPOIST_DATA.map((item) => {
          return (
            <button onClick={() => selectAmount(item)} key={item} className={`${styles['amount']}`}>{item}</button>
          )
        })}
      </div>
      <div className={styles['hint']}>1 USDT = {toFixed(1/COINS_PRICE, 0)} gamecoin</div>
      <div className={styles['hint']}>Minimum recharge of 10 gamecoin</div>
    </div>
    <button
      onClick={deposit}
      className={styles['deposit-confirm']}>Deposit</button>
  </div>)
}

export default DepositModal