import {useEffect, useState} from 'react'
import styles from './index.module.scss'
import { useSelector } from "react-redux";
import { toFixed, setMultiple } from '../../../../utils'
import END from '../../../../assets/images/a/mining-end-icon.png'


const Countdown = ({time, btnStatus, dropNot}) => {
  const [amount, setAmount] = useState(0)
  const {
    buyNot,
    notNumber
  } = useSelector((state) => state.user);
  useEffect(() => {
    const _amount = toFixed(parseFloat(buyNot*setMultiple(notNumber)+dropNot),3)
    setAmount(_amount)
  }, [buyNot, notNumber, dropNot])
  
  return (
    <div className={`${styles["countdown-container"]}`}>
      {time!==0&&btnStatus==='on'&&<div className={styles['time-box']}>
        <div className={styles['time']}>{time}</div>
        <div className={styles['text']}> s</div>
      </div>}
      {btnStatus==='off'&&(<div className={styles['waiting-box']}>
        <img className={styles['waiting-img']} src={END} alt="icon" />
        <div className={styles['waiting']}>+{amount} NOT</div>
      </div>)}
    </div>
  )
}

export default Countdown;

