import React, { useState, useEffect } from 'react'
import { on } from '@tma.js/sdk';
import { useNavigate } from 'react-router-dom'
import Header from "../../layout/HeaderV2"
import Game from "./components/Game"
import Tab from '../../layout/Tab'
import styles from "./index.module.scss"
import FirstAward from '../../components/FirstAward'

const Home = () => {
  const [showFirst, setShowFirst] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const removeBack =  on('back_button_pressed', () => {
      console.log('earn')
      navigate(-10000)
    });
    return () => {
      removeBack()
    }
  }, [])

  const handleOpen = () => {
    setShowFirst(true)
  }

  const handleClose = () => {
    setShowFirst(false)
  }

  return (
    <div className={`${styles["home-container"]}`}>
      <Header type="show"></Header>
      <div className={`${styles["contents"]}`}>
        <Game openAward={handleOpen} />
      </div>
      {showFirst && <FirstAward close={handleClose} />}
      <Tab type='1' />
    </div>);
}

export default Home;