import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useInitDataRaw, useUtils, useBackButton } from '@tma.js/sdk-react';
import Header from "../../layout/HeaderV2"
import { Popup } from 'antd-mobile'
import GROUP_IMG from '../../assets/images/a/task_icon.png'
import CLOSE_ICON from '../../assets/images/a/earn_close_icon.png'
import LOGO_ICON from '../../assets/images/a/earn_logo_icon.png'
import DONE from '../../assets/images/a/task_done_icon.png'
import MainButton from '../../components/MainButton'
import Tab from '../../layout/Tab'
import styles from './index.module.scss'
import {useNavigate} from 'react-router-dom'
import { on } from '@tma.js/sdk';
import { dailyTasks, taskList, checkTask, adTask, finishAdTask, honorList, honorReward, honorFinish, honorHook } from '../../utils/api'
import MyToast from '../../components/MyToast'
import { userSlice } from "../../redux/user/slice";
import Link from "../../components/Link"
import { setStorage, getStorage, toFixed } from '../../utils'
import IMG1 from '../../assets/images/a/not_coins.png'
import IMG2 from '../../assets/images/a/not_coins_2.png'
import IMG5 from '../../assets/images/img_5.png'
import EARN_BANNER from '../../assets/images/earn_banner.png'
import EarnModal from '../../components/EarnModal';
import StartModal from '../../components/StartModal';
import LV1 from '../../assets/images/lv1_icon.png'
import LV2 from '../../assets/images/lv2_icon.png'
import LV3 from '../../assets/images/lv3_icon.png'
import LV4 from '../../assets/images/lv4_icon.png'
import LV5 from '../../assets/images/lv5_icon.png'

// import DONE from '../../assets/images/task_done.png'
import DONE2 from '../../assets/images/task_done.png'
import UNDO from '../../assets/images/task_undo.png'
import BOX_OFF from '../../assets/images/box2_off.png'
import BOX_ON from '../../assets/images/box2_on.png'

const LEVEL = {
  LV1: {
    level: 1,
    img: LV1,
    next: LV2,
    point: 60,
    proportion: '100%'
  },
  LV2: {
    level: 2,
    img: LV2,
    next: LV3,
    point: 600,
    proportion: '120%'
  },
  LV3: {
    level: 3,
    img: LV3,
    next: LV4,
    point: 1400,
    proportion: '140%'
  },
  LV4: {
    level: 4,
    img: LV4,
    next: LV5,
    point: 2000,
    proportion: '160%'
  },
  LV5: {
    level: 5,
    img: LV5,
    next: LV5,
    point: 2001,
    proportion: '200%'
  }
}

const ADS_ID = "6fced4eee9927b57847cf8dce447ceac"
const ZONE_ID = '28'
const ADD_AD_ID = '9999' // 为避免与taskId重复，adId 加一个常数
const WEEK = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
  7: '7th'
}

const openAdExtend = {
  key: 'openADSDK',
  adInfo: {
    zoneId: ZONE_ID,
    reviveId: ADS_ID,
  },
}
export function buildArray(n, m) {
  let result = [];
  for (let i = 0; i < n; i++) {
    result.push(i < m ? 1 : 0);
  }
  return result;
}

const defaultArr = buildArray(5, 0)

const Earn = () => {

  const [adImg, setAdImg] = useState({
    'width': '',
    'height': '',
    'src': '',
    'bannerid': '',
    'campaignid': '',
    'zoneid': '',
    'loc': '',
    'referer': '',
    'cb': '',
    'sig': '',
  })

  const [adAPP, setAdAPP] = useState(null)
  const [adCode, setAdCode] = useState(-3)

  const [checkinToday, setCheckinToday] = useState(1)
  const [list, setList] = useState([])
  const [earnList, setEarnList] = useState([])
  const [ad, setAd] = useState({})
  const [tomorrow, setTomorrow] = useState(0)
  const [earn, setEarn] = useState(false)
  const [start, setStart] = useState(false)
  const [earnNumber, setEarnNumber] = useState('')
  const [listBar, setListBar] = useState([])
  const [taskType, setTaskType] = useState(1)
  const [newList, setNewList] = useState([])
  const [taskTotal, setTaskTotal] = useState(0)
  const [taskFinish, setTaskFinish] = useState(0)
  const [] = useState(0)

  const backButton = useBackButton();
  const initDataRaw = useInitDataRaw()

  const navigate = useNavigate()

  const utils = useUtils();

  const dispatch = useDispatch();

  const {
    amount,
    user
  } = useSelector((state) => state.user);

  useEffect(() => {
    backButton.hide();
  },[])

  useEffect(() => {
    getDailyTasks()
    getEarnList()
    loadJsV2()
  }, [])

  //第一次显示新人弹窗
  useEffect(() => {
    const newLogin =  getStorage("newLogin")
    if (newLogin) {
      return
    }
    setStorage("newLogin", '1')
    setStart(true)
  }, [])

  useEffect(() => {
    getNewTask(taskType)
  }, [taskType])


  const loadJsV2 = async () => {
    const key = openAdExtend.key;
    setAdAPP(window[key])
    let res = await window[key].init(openAdExtend.adInfo);
    console.log('res', JSON.stringify(res));
    setAdCode(res.code)
    if(res.code === 0){
      setAdImg(res.data)
    }
  }

  const clickCb = () => {
    let params = { bannerid: adImg.bannerid, zoneid: adImg.zoneid, sig: adImg.sig };
    adAPP.click.cb(params, utils);
  }

  const selectTaskType = (type) => {
    setTaskType(type)
  }


  const getDailyTasks = async () => {
    const res = await dailyTasks()
    if (res.code !==0) {
      return
    }
    const {checkin_today, list} = res.data
    const _tomorrow = list.find((item) => {
      return item.checkin === 0
    }).day
    setTomorrow(_tomorrow)
    setCheckinToday(checkin_today)

    setList(list)
  }
  
  // earn list
  const getEarnList = async () => {
    const res = await adTask()
    if (res.code !==0) {
      return
    }

    const _hasTask = res.data.findIndex(x => {
      return x.status !== '3'
    })

    if (_hasTask===-1) {
      dispatch(
        userSlice.actions.setShowDot({
          showDot: false
        })
      )
    }

    const _data = res.data.map(item => {
      return {
        ...item,
        visible: false
      }
    })

    // 排序 未完成(status=1)的放前面
    _data.sort((a, b) => {
      return a.status - b.status
    })

    console.log('_data', _data)
    setEarnList(_data)
  }

  const openModal = (id) => {
    const _data = earnList.map(item => {
      return {
        ...item,
        visible: item.task_id === id ? true : false
      }
    })
    setEarnList(_data)
  }

  const closeModal = () => {
    const _data = earnList.map(item => {
      return {
        ...item,
        visible: false
      }
    })
    setEarnList(_data)
  }

  const openModal2 = (id) => {
    const _data = newList.map(item => {
      return {
        ...item,
        visible: item.id === id ? true : false
      }
    })
    setNewList(_data)
  }

  const closeModal2= () => {
    const _data = newList.map(item => {
      return {
        ...item,
        visible: false
      }
    })
    setNewList(_data)
  }

  const handleAd = (e, adId) => {
    e.stopPropagation();
    window.gtag('event', 'click_ad_award_task', {
      'click_ad_award': 'on'
    })
    clickCb()
    setStorage(`task_${adId}`, '1')
  }

  const addGroup = (item, e) => {
    const { link, link_type, image_url } = item
    if (!link) {
      return
    }
    
    if (image_url) {
      window.gtag('event', 'click_award_funton_ai_join', {
        'click_award_task_join': 'on'
      })
    }
    e.stopPropagation();

    setStorage(`task_${item.task_id}`, '1')
    if (link_type === '1') {
      utils.openTelegramLink(link)
      return
    } else {
      utils.openLink(link)
      return
    }

  }

  const addGroup2 = (item, e) => {
    const { link, link_type, type, id } = item
    if (!link) {
      return
    }
    e.stopPropagation();

    if (type===2 || type===8) {
      honorHook(id)
    }

    setStorage(`task_${item.task_id}`, '1')
    if (link_type === '1') {
      utils.openTelegramLink(link)
      return
    } else {
      utils.openLink(link)
      return
    }

  }

  const handleCheckAd = async (adId) => {
    console.log('adId', adId)
    const isClink = getStorage(`task_${adId}`)
    if (isClink !== '1') {
      MyToast({
        content: "Task isn't completed",
        position: 'top',
      })
      return
    }

    const res = await finishAdTask(parseInt(adId))

    setAd({...ad, visible: false})
    if (res.code === -20101) {
      MyToast({
        content: "You have completed the task",
        position: 'top',
      })
      return
    }
    if (res.code !==0) {
      MyToast({
        content: "Network Error",
        position: 'top',
      })
      return 
    }
    if (res.data.is_finished === 4) {
      MyToast({
        content: "Task isn't completed",
        position: 'top',
      })
      return
    }

    if (res.data.is_finished === 3) {
      MyToast({
        content: "Congratulations, Task is completed",
        position: 'top',
      })
      return
    }

  }

  const handleCheck = async (taskId, link, type, reward) => {
    // type 1:签到  , 2:任务

    const isClink = getStorage(`task_${taskId}`)
    console.log('isClink', isClink)
    if (link && isClink !== '1') {
      MyToast({
        content: "Task isn't completed",
        position: 'top',
      })
      return
    }

    const _data = {
      task_id: parseInt(taskId),
      init_data: initDataRaw
    }
    const res = await checkTask(_data)
    closeModal()
    if (res.code !==0) {
      MyToast({
        content: "Network Error",
        position: 'top',
      })
      return 
    }
    if (res.data.is_finished === 4) {
      MyToast({
        content: "Task isn't completed",
        position: 'top',
      })
      return
    }

    if (type === 1 && res.data.is_finished === 3) {
      await getDailyTasks()
      MyToast({
        content: 'Sign in successfully',
        position: 'top',
      })
      return
    }

    if (type === 2 && res.data.is_finished === 3) {
      setEarnNumber(reward)
      await getEarnList()
      MyToast({
        content: "Congratulations, Task is completed",
        position: 'top',
      })
      setEarn(true)
      return
    }
  }

  const handleCheck2 = async (reward, userTaskStatus, userTaskId) => {
    if (userTaskStatus === 0) {
      MyToast({
        content: "Task isn't completed",
        position: 'top',
      })
      return
    }

    if (userTaskStatus === 3) {
      MyToast({
        content: "Invalid task",
        position: 'top',
      })
      return
    }

    const res = await honorReward(parseInt(userTaskId))
    closeModal()
    if (res.code !==0) {
      MyToast({
        content: "Network Error",
        position: 'top',
      })
      return 
    }
    if (res.data.is_finished === 4) {
      MyToast({
        content: "Task isn't completed",
        position: 'top',
      })
      return
    }

    if (res.data.is_finished === 3) {
      setEarnNumber(reward)
      await getNewTask(taskType)
      MyToast({
        content: "Congratulations, Task is completed",
        position: 'top',
      })
      setEarn(true)
      
      return
    }
  }

  const getNewTask = async (type) => {
    const res = await honorList(type)
    if (res.code !==0 ) {
      return
    }
    setListBar(buildArray(res.data.task_total, res.data.task_finish_num))
    setNewList(res.data.task)
    setTaskTotal(res.data.task_total)
    setTaskFinish(res.data.task_finish_num)
  }
  const handleOpenBox = async () => {
    const res = await honorFinish(taskType)
    if (res.code !==0) {
      MyToast({
        content: "Network Error",
        position: 'top',
      })
      return 
    }
    if (res.data.is_finished === 4) {
      MyToast({
        content: "Task isn't completed",
        position: 'top',
      })
      return
    }

    if (res.data.is_finished === 3) {
      setEarnNumber('3')
      MyToast({
        content: "Congratulations, Task is completed",
        position: 'top',
      })
      setEarn(true)
      return
    }
  }

  return (
    <div className={styles["earn-container"]}>
      <Header type="hide" />
      {adCode === 0 && <div className={styles['ads-container']}>
        {/* <div className={styles['point']}>+100</div> */}
        <div className={styles['award-box']}>
          <div className={styles['award-btn']} onClick={(e)=> {
            e.stopPropagation()
            setAd({...ad, visible: true})
          }}>
            <img className={styles['award-img1']} src={IMG2} alt="img" />
            +3
            <img className={styles['award-img2']} src={IMG5} alt="img" />
          </div>
        </div>
        {/* <div id='ads_1001' onClick={(e) =>handleAd(e, 9999)} className={`${styles['open-ads']} openAds`}>
          <ins data-revive-zoneid="28" data-revive-id={ADS_ID} />
        </div> */}
        <div onClick={(e) =>handleAd(e, `${ADD_AD_ID}${adImg.bannerid}`)} className={styles['open-ads']}>
          <img
            src={adImg.src}
            width={adImg.width}
            height={adImg.height}
            style={{maxWidth: '100%',maxHeight: '100%',objectFit: 'contain'}}
          />
        </div>
        <Popup
          visible={ad.visible}
          onMaskClick={() => {
            setAd({...ad, visible: false})
          }}
          onClose={() => {
            setAd({...ad, visible: false})
          }}
          bodyStyle={{
            minHeight: '40vh',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            background: '#202020'
          }}
        >
          <div className={styles['popup-box']}>
            <img onClick={() => setAd({...ad, visible: false})} src={CLOSE_ICON} alt='icon' className={styles['close-icon']} />
            <div className={styles['title']}></div>
            <img src={LOGO_ICON} alt='icon' className={styles['logo-icon']} />
            <div className={styles['popup-point']}>+{3} NOT</div>
            <div className={styles['popup-btn-box']}>
              {/* 付费广告类型 */}
              <MainButton onClick={() => handleCheckAd(`${ADD_AD_ID}${adImg.bannerid}`)} padding='16px 0' style={{fontSize: '17px', flex: '1'}}>Check</MainButton>
            </div>
          </div>
        </Popup>
      </div>}
      {/* Card */}
      <div className={styles['card-box']}>
        <div className={styles['line1']}>
          <div className={styles['left']}>
            <img src={LEVEL[`LV${user.honorLevel || '1'}`].img} alt="lv" />
            <div className={styles['left-text']}>{LEVEL[`LV${user.honorLevel || '1'}`].proportion}</div>
          </div>
          <div className={styles['right']}>
            <div className={styles['text']}>Weekly Honor Value</div>
            <div className={styles['bar']}>
              <div style={{width: '50%'}} className={styles['width']}></div>
            </div>
            <div className={styles['info']}>
              <div>({user.honor}/{LEVEL[`LV${user.honorLevel || '1'}`].point})</div>
              <img src={LEVEL[`LV${user.honorLevel || '1'}`].next} alt="lv" />
            </div>
          </div>
        </div>
        <div className={styles['line2']}>
          <img src={IMG2} />
          <div className={styles['amount']}>{toFixed(user.unlockScore, 0)}</div>
        </div>
        <div className={styles['line3']}>
          The NOT I earned
        </div>
      </div>
      
      {/* Earn NOT */}
      <div className={styles['earn-not']}>
        <div className={styles['earn-title']}>
          Earn NOT
          <img src={IMG2} alt="img" />
        </div>
        <div className={styles['earn-not-list']}>
          {earnList.map((item) => {
            return (<div className={styles['not-item']}>
              <img className={styles['item-icon']} src={item.image_url || GROUP_IMG} alt="icon" />
              <div className={styles['not-title']}>{item.title}</div>
              <div className={styles['item-info']}>
                <div className={styles['award-not']}>
                  {item.type === 5  && <img src={IMG2} alt="icon" />}
                  {item.type !== 5  && <img src={IMG1} alt="icon" />}
                  +{item.reward}
                </div>
                {/* <div className={styles['liveness-point']}>liveness +50</div> */}
                <div style={{flex:'1'}}></div>
                {item.status === '3' ? <img src={DONE} alt="icon" className={styles['done']} />:<div className={styles['not-btn']}
                  onClick={() => {
                    openModal(item.task_id)
                  }}
                >Award</div>}
              </div>
              <Popup
                visible={item.visible}
                onMaskClick={() => {
                  closeModal()
                }}
                onClose={() => {
                  closeModal()
                }}
                bodyStyle={{
                  minHeight: '40vh',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                  background: '#202020'
                }}
              >
                <div className={styles['popup-box']}>
                  <img onClick={() => closeModal()}  src={CLOSE_ICON} alt='icon' className={styles['close-icon']} />
                  <div className={styles['title']}>{item.title}</div>
                  <img src={LOGO_ICON} alt='icon' className={styles['logo-icon']} />
                  <div className={styles['popup-point']}>+{item.reward} NOT</div>
                  <div className={styles['popup-btn-box']}>
                    {item.link && <MainButton onClick={(e)=>addGroup(item, e)} padding='16px 0' style={{fontSize: '17px', flex: '1', marginRight: '16px'}}>Go</MainButton>}
                    <MainButton onClick={() => handleCheck(item.task_id, item.link, 2, item.reward)} padding='16px 0' style={{fontSize: '17px', flex: '1'}}>Check</MainButton>
                  </div>
                </div>
              </Popup>
            </div>)
          })}
        </div>
      </div>

      <div className={styles['label-box']}>
        <div onClick={() => selectTaskType(1)} className={`${styles['label-item']} ${taskType===1 ? styles['selected']:''}`}>Newbie Task</div>
        <div onClick={() => selectTaskType(2)} className={`${styles['label-item']} ${taskType===2 ? styles['selected']:''}`}>Daily tasks</div>
        <div onClick={() => selectTaskType(3)} className={`${styles['label-item']} ${taskType===3 ? styles['selected']:''}`}>Weekly tasks</div>
      </div>

      {/* 宝箱 */}
      {listBar.length>0 && <div className={styles["task-info-box"]}>
        <div className={styles["task-info"]}>
          <div className={styles["list"]}>
            <div className={styles['bar']}></div>
            {listBar.map((x, index) => {
              return (<img key={index} src={x===1 ? DONE2 : UNDO} alt="icon" />)
            })}
          </div>
          <div onClick={handleOpenBox} className={styles["new-box"]}>
            {taskTotal === taskFinish && <div className={styles["dian"]}></div>}
            {/* <img src={BOX_ON} alt="box" /> */}
            <img src={BOX_OFF} alt="box" />
          </div>
        </div>
      </div>}

      <div className={styles['list']}>
        {/* <div className={styles['list-title']}>Earn List</div> */}

        {newList.map((item) => {
          return (
            <div key={item.task_id} className={styles['item']}>
              <img src={item.image_url || GROUP_IMG} alt="icon" className={styles['icon']} />
              <div onClick={(e)=>addGroup2(item, e)} className={styles['item-detail']}>
                <div className={styles['text']}>{item.title}</div>
                <div className={styles['point-box']}>
                  <div className={styles['point']}>
                    {item.unlock_not&& <img src={IMG2} alt="icon" />}
                    {!item.unlock_not && <img src={IMG1} alt="icon" />}
                    +{item.reward} NOT
                  </div>
                  <div className={styles['honor-box']}>
                    honor +{item.honor}
                  </div>
                  {/* {item.executable !== 0 && <div className={styles['overage']}>Overage: {parseInt(item.executable-item.finished)}</div>} */}
                </div>
                {item.executable !== 0 && <div className={styles['ad-bar']}>
                  <div style={{width: `${parseInt(item.finished/item.executable*100)}%`}} className={styles['bar-width']}></div>
                </div>}
                {item.executable !== 0 && <div className={styles['task-number']}>Number of recruits now: <span style={{color: '#FFA800'}}>{parseInt(item.executable-item.finished)}</span></div>}
              </div>
              <div className={styles['btn-box']}>
                {item.user_task_status === 2 ? <img src={DONE} alt="icon" className={styles['done']} />:
                <div className={styles['not-btn']} onClick={() => {
                  openModal2(item.id)
                }}>Award</div>}
              </div>
              <Popup
                visible={item.visible}
                onMaskClick={() => {
                  closeModal2()
                }}
                onClose={() => {
                  closeModal2()
                }}
                bodyStyle={{
                  minHeight: '40vh',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                  background: '#202020'
                }}
              >
                <div className={styles['popup-box']}>
                  <img onClick={() => closeModal2()}  src={CLOSE_ICON} alt='icon' className={styles['close-icon']} />
                  <div className={styles['title']}>{item.title}</div>
                  <img src={LOGO_ICON} alt='icon' className={styles['logo-icon']} />
                  <div className={styles['popup-point']}>+{item.reward} NOT</div>
                  <div className={styles['popup-btn-box']}>
                    {item.link && <MainButton onClick={(e)=>addGroup2(item, e)} padding='16px 0' style={{fontSize: '17px', flex: '1', marginRight: '16px'}}>Go</MainButton>}
                    <MainButton onClick={() => handleCheck2(item.reward, item.user_task_status, item.user_task_id)} padding='16px 0' style={{fontSize: '17px', flex: '1'}}>Check</MainButton>
                  </div>
                </div>
              </Popup>
            </div>
          )
        })}
        
        {/* <div className={styles['item']}>
          <img src={GROUP_IMG} alt="icon" className={styles['icon']} />
          <div className={styles['item-detail']}>
            <div className={styles['text']}>Group entry reward</div>
            <div className={styles['point']}>+10000</div>
          </div>
          <img src={DONE} alt="icon" className={styles['done']} />
        </div> */}
      </div>
      {earn && <EarnModal number={earnNumber} close={() => setEarn(false)} />}
      {start && <StartModal close={() => setStart(false)} />}
      <Tab type='3' />
    </div>
  )
}

export default Earn;
