import React, { useState, useEffect } from 'react'
import { toFixed } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import styles from './index.module.scss'
import { login, userInfo, getGameInfo, digTask } from '../../utils/api'
import { TOKEN, ENV, testInitData, UP_OR_DOWN_LIST } from '../../constants'
import { userSlice } from "../../redux/user/slice";
import { useInitDataRaw } from '@tma.js/sdk-react';
import CountUp from 'react-countup';
import { useInterval } from 'usehooks-ts'
import MyToast from '../../components/MyToast'
import Link from "../../components/Link"
import coinsIcon from "../../assets/images/a/not_coins.png"
import { animated, useSpring } from '@react-spring/web'
import FirstImg from "../../pages/home/components/FirstImg"

const HeaderV2 = ({type}) => {
  const [isLogin, setIsLogin] = useState(false)
  const [showModal, setShowModal] = useState(false)

  // TEST | PROD
  const initDataRaw = useInitDataRaw()

  // DEV
  // const initDataRaw = testInitData

  const [springs, api] = useSpring(() => ({
    from: { transform: 'scale(1)' },
  }))

  const {
    amount,
    user,
    showSpring
  } = useSelector((state) => state.user);

  useEffect(() => {
    console.log('initDataRaw====', initDataRaw)
    const hash = window.location.hash.slice(1)
    const params = new URLSearchParams(decodeURIComponent(hash));
    console.log('start_param=', params.get('start_param'));
    const inviteId = params.get('start_param') || ''
    userLogin(inviteId)
  }, [])

  useEffect(() => {
    api.start({
      from: {
        transform: 'scale(1)'
      },
      to: {
        transform: 'scale(2)'
      },
      reset: true,
      reverse: true
    })
  }, [amount])

  useInterval(() => {
    console.log(isLogin)
    if (!isLogin) {
      return
    }
    loginAndGetUserInfo()
  }, 10000)
  
  const dispatch = useDispatch();
  const loginAndGetUserInfo = async () => {
    await getUserInfo()
  }

  // wake userId 1499574699
  const userLogin = async (inviteId) => {
    const res = await login({
      init_data: initDataRaw,
      url_data: inviteId
    })

    if (res.code == -1004) {
      dispatch(
        userSlice.actions.setRejectIP({
          rejectIP: true
        })
      )
      return
    } else {
      dispatch(
        userSlice.actions.setRejectIP({
          rejectIP: false
        })
      )
    }

    if (res.code !== 0) {
      // Cookies.remove(TOKEN);
      localStorage.removeItem(TOKEN);
      MyToast({
        content: 'Login failed, please try to re-enter the game',
        position: 'top',
        duration: 6000
      })
      dispatch(
        userSlice.actions.setAmount({
          amount: 0
        })
      )
      dispatch(
        userSlice.actions.setUpPool({
          upPool: 0,
        })
      );
      dispatch(
        userSlice.actions.setDownPool({
          downPool: 0,
        })
      );
      dispatch(
        userSlice.actions.setUpList({
          upList: UP_OR_DOWN_LIST,
        })
      );
      dispatch(
        userSlice.actions.setDownList({
          downList: UP_OR_DOWN_LIST,
        })
      );
      return
    }
    console.log('res====', res)
    // Cookies.set(TOKEN, res.data.token);
    localStorage.setItem(TOKEN, res.data.token);

    digTask()
    setIsLogin(true)
    dispatch(
      userSlice.actions.setShowSpring({
        showSpring: false
      })
    )
    loginAndGetUserInfo()
  }

  // 获取各个状态时间
  const getTime = async () => {
    const res = await getGameInfo()
    if (res.code !== 0) {
      return 0
    }
    const { gaming_seconds, settle_seconds, staking_seconds, wait_start_seconds, cur_room_id } = res.data
    dispatch(
      userSlice.actions.setTime({
        time: {
          gamingSeconds: gaming_seconds,
          settleSeconds: settle_seconds,
          stakingSeconds: staking_seconds,
          waitStartSeconds: wait_start_seconds
        },
      })
    );
    return cur_room_id
  }

  const getUserInfo = async () => {
    const res = await userInfo();
    console.log('userInfo', res.data)

    if (res.code !== 0) {
      return
    }
    console.log('res====', res)
    const { id, email, avatar, user_type, score, describe, level, level_begin, level_end, energy, tg_id, top_level, invitation_reward, unlock_score, honor, honor_level } = res.data
    dispatch(
      userSlice.actions.setUserType({
        userType: user_type
      })
    )
    dispatch(
      userSlice.actions.setAmount({
        amount: score
      })
    )
    dispatch(
      userSlice.actions.setUser({
        user: {
          id: id,
          tgId: tg_id,
          email: email,
          img: avatar,
          name: describe,
          point: score,
          level: level,
          levelBegin: level_begin,
          levelEnd: level_end,
          energy: energy,
          topLevel: top_level,
          invitationReward: invitation_reward,
          unlockScore: unlock_score,
          honor: honor,
          honorLevel: honor_level
        }
      })
    )
  }

  // const utils = new Utils(
  //   '6.10',
  //   () => Math.random().toString(),
  //   postEvent,
  // );

  // const handleWallet = (item) => {
    
  //   // tg 测试环境
  //   // utils.openTelegramLink('https://t.me/betbtcBot/wallet');

  //   // tg 正式环境的测试
  //   utils.openTelegramLink('https://t.me/testbetbtcBot/wallet');
  // }


  const getWidth = (begin=0, end=0, score=0) => {
    if (score <= begin) {
      return '0%'
    }
    if (end<=begin || end===0) {
      return '0%'
    }
    if (score >= end) {
      return '100%'
    }

    const _width = toFixed((score - begin)*100 / (end - begin), 0)

    return `${_width}%`
  }
  return (
    <>
      {type === 'show' && <div className={styles["header-container"]}>
        <Link className={styles['amount']}>
          <div style={{width: getWidth(user.levelBegin, user.levelEnd, user.point)}} className={styles['point-bar']}></div>
          <span className={styles['point-text']}>{getWidth(user.levelBegin, user.levelEnd, user.point)}</span>
          <img src={coinsIcon} className={styles['wallet']} alt="wallet" />
          <animated.div style={{...springs}} className={styles['text']}>
            <CountUp preserveValue decimals={amount==0 ? 0:3} end={toFixed(amount,3)} />
          </animated.div>
        </Link>
        <div className={styles['right-box']}>
          <span className={styles['text1']}>LV{user.level}</span>
          <span className={styles['text2']}>/{user.topLevel}</span>
        </div>
      </div>}
      {/* {showSpring && <FirstImg />} */}
    </>
  )
}

export default HeaderV2;
