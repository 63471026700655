import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { HtmlLabel, CircleSubject, LineSubject } from '@visx/annotation';
import { LinePath } from '@visx/shape';
import ExampleControls from './ExampleControls';
import { AxisRight } from '@visx/axis';
import { GridRows } from '@visx/grid';
import Loading from "../../components/Loading"

import styles from './index.module.scss'
import { GAME_STATUS, LINE_WIDTH } from '../../constants'
import { toFixed } from '../../utils';

const { ANTE_START, ANTE_IN, GAME_START_BEFORE, GAME_START, GAME_IN, GAME_END, GAME_END_PENDING } = GAME_STATUS

export const orange = '#ff7e67';
export const greens = ['#0c0d13', '#68b0ab', '#fbd759'];


let timer = null

const TimeOut = () => {

  const {
    statusTime
  } = useSelector((state) => state.user);
  const { waitStartSeconds } = statusTime

  const [time, setTime] = useState(Math.floor(waitStartSeconds/1000))

  useEffect(() => {
    timer && clearInterval(timer);
    return () => timer && clearInterval(timer);
  }, []);

  useEffect(()=> {
    if( time === Math.floor(waitStartSeconds/1000) ) {
      timer = setInterval(()=> setTime(time => --time), 1000)
    }
    else if ( time <= 0 ) {
      clearInterval(timer)
    }
  }, [time])

  return (
    <div className={styles['count-down']}>{time-1<0? '' : time-1||'Go'}</div>
  )

}

const ResultText = ({text=''}) => {
  return (
    <div className={styles['result']}>{text}</div>
  )
}


const findStart = (arr) => {
  // const start = appleStock[(TIME1+TIME2)/200+100].close
  const ind = arr.findIndex(x => {
    return x.status === GAME_START
  })
  return (ind === -1) ? 0 : ind
}


export default function Example({ width, height, compact = false }) {

  const {
    statusTime
  } = useSelector((state) => state.user);
  const { gamingSeconds, settleSeconds, waitStartSeconds } = statusTime

  const computeWidth1 = ({width, time, status, data}) => {
    if (status === ANTE_START || status === ANTE_IN) {
      const one = LINE_WIDTH*width/data.length
      return (time+waitStartSeconds)/200*one
    }
    if (status === GAME_START_BEFORE) {
      const one = LINE_WIDTH*width/data.length
      return time<0? 0 : time/200*one
    }
    if (status === GAME_IN) {
      const one = LINE_WIDTH*width/data.length
      return time/200*one
    }
    if (status === GAME_END_PENDING) {
      const one = LINE_WIDTH*width/data.length
      return -(settleSeconds-time)/200*one
    }
    if (status===GAME_END) {
      return 0
    }
    return 0
  }

  const computeWidth2 = ({width, time, status, data}) => {
    if (status === ANTE_START || status === ANTE_IN) {
      const one = LINE_WIDTH*width/data.length
      return (time+waitStartSeconds+gamingSeconds)/200*one
    }
    if (status === GAME_START_BEFORE) {
      const one = LINE_WIDTH*width/data.length
      return (time+gamingSeconds)/200*one
    }
    if (status === GAME_IN || status===GAME_START) {
      const one = LINE_WIDTH*width/data.length
      return gamingSeconds/200*one
    }
    if (status === GAME_END_PENDING) {
      const one = LINE_WIDTH*width/data.length
      return gamingSeconds/200*one - ((settleSeconds-time)/200*one)
    }
    if (status===GAME_END) {
      const one = LINE_WIDTH*width/data.length
      return gamingSeconds/200*one
    }
    return 0
  }
  return (
    <ExampleControls width={width} height={height} compact={compact} >
      {({
        AnnotationComponent,
        annotationPosition,
        startPosition,
        approxTooltipHeight,
        connectorType,
        datax,
        editLabelPosition,
        editSubjectPosition,
        getDate,
        getStockValue,
        horizontalAnchor,
        labelType,
        labelWidth,
        setAnnotationPosition,
        showAnchorLine,
        subjectType,
        subtitle,
        title,
        verticalAnchor,
        xScale,
        yScale,
        status,
        timeCode,
        btcStatus,
        betStatus,
        loading,
        connectionStatus
      }) => (
        <div className={styles["chart-container"]}>
          {/* <span>The WebSocket is currently {connectionStatus}</span> */}
          <div className={styles['btc-icon']}>
            {status===GAME_START_BEFORE && <TimeOut />}
            
            {status===GAME_END_PENDING && ((betStatus===1 && btcStatus==='up') || (betStatus===2 && btcStatus==='down')) && <ResultText text={'YOU WIN'} />}
            {status===GAME_END_PENDING && ((betStatus===1 && btcStatus==='down') || (betStatus===2 && btcStatus==='up')) && <ResultText text={'YOU LOSE'} />}
          </div>
         
          <svg className={styles['svg-group']} width={width} height={height}>
            {/* <rect width={width} height={height} fill={greens[0]} /> */}
            <defs>

              {/* 上下区域颜色配置 */}
              <linearGradient id="graph_green_grad" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{stopColor: 'rgba(142, 255, 72, 0)', stopOpacity: '0.9' }}></stop>
                <stop offset="45%" style={{stopColor: 'rgba(142, 255, 72, 0.25)', stopOpacity: '0.9' }}></stop>
                <stop offset="90%" style={{stopColor: 'rgb(142, 255, 72, 0.8)', stopOpacity: '0.9' }}></stop>
              </linearGradient>
              <linearGradient id="graph_red_grad" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="10%" style={{stopColor: 'rgb(255, 0, 46)', stopOpacity: '0.9' }}></stop>
                <stop offset="55%" style={{stopColor: 'rgba(255, 0, 46, 0.25)', stopOpacity: '0.9' }}></stop>
                <stop offset="100%" style={{stopColor: 'rgba(237, 0, 46, 0)', stopOpacity: '0.9' }}></stop>
              </linearGradient>
            </defs>
            <AnnotationComponent
              width={width}
              height={height}
              x={annotationPosition.x}
              y={annotationPosition.y}
              dx={annotationPosition.dx}
              dy={annotationPosition.dy}
              canEditLabel={editLabelPosition}
              canEditSubject={editSubjectPosition}
            > 
              {/* 颜色区域 上绿下红 分两种情况: 押注时 / 比赛时 */}
              {status === ANTE_IN||status === GAME_START_BEFORE ?(<>
                <rect x="0" y="0" width={width} height={annotationPosition.y} fill="url(#graph_green_grad)" opacity="0.8"></rect>
                <rect x="0" y={annotationPosition.y} width={width} height={height-annotationPosition.y} fill="url(#graph_red_grad)" opacity="0.8"></rect>
              </>):(<>
                <rect x="0" y="0" width={width} height={startPosition.y} fill="url(#graph_green_grad)" opacity={btcStatus==='up'?"1":"0.8"}></rect>
                <rect x="0" y={startPosition.y} width={width} height={height-startPosition.y} fill="url(#graph_red_grad)" opacity={btcStatus==='down'?"1":"0.8"}></rect>
              </>)}

              {/* 每次比赛结束后4秒把线消失 */}
              {(status!==GAME_END_PENDING || (status===GAME_END_PENDING&&timeCode>settleSeconds-4000)) && status!==ANTE_START && (<>

                {/* 比赛开始线 */}
                <LineSubject
                  stroke="#FFA800"
                  strokeDasharray="1,3"
                  orientation="vertical"
                  x={
                    status===GAME_IN || status === GAME_START ? 
                    startPosition.x : 
                    status===GAME_END_PENDING || status===GAME_END ?
                    computeWidth1({width, time:timeCode, status, data:datax}) : 
                    annotationPosition.x+computeWidth1({width, time:timeCode, status, data:datax})
                  }
                  min={40}
                  max={height-40} />

                {/* 比赛结束线 */}
                <LineSubject
                  stroke="#FFA800"
                  strokeDasharray="1,3"
                  orientation="vertical"
                  x={status===GAME_IN || status===GAME_START ? startPosition.x + computeWidth2({width, time:timeCode, status, data:datax}) : status===GAME_END_PENDING || status===GAME_END ? computeWidth2({width, time:timeCode, status, data:datax}) : annotationPosition.x+computeWidth2({width, time:timeCode, status, data:datax})}
                  min={40}
                  max={height-40} />

                {/* 比赛开始的小旗子 */}
                <HtmlLabel
                  anchorLineStroke="rgba(255,255,255,0)"
                  containerStyle={{
                    width: '20px',
                    height: '20px'
                  }}
                  x={status===GAME_IN || status===GAME_START ? startPosition.x+10 : status===GAME_END_PENDING || status===GAME_END ? computeWidth1({width, time:timeCode, status, data:datax})+10 : 10+annotationPosition.x+computeWidth1({width, time:timeCode, status, data:datax})}
                  y={20}
                >
                  <svg viewBox="0 0 13 14" width="20" height="20"><path opacity="0.8" fill="#f4d56f" fillRule="evenodd" d="M12.958 6.124 11.281.58c-.047-.161-.1-.344-.283-.394-.185-.05-.325.077-.487.225-.375.342-1.437 1.117-3.209.967l-.24-.79L7.06.58C6.992.372 6.933.192 6.607.134A8.291 8.291 0 0 0 5.161 0C3.439 0 2.029.577.859 1.838l-.067-.222a.406.406 0 0 0-.503-.266.394.394 0 0 0-.272.492l3.609 11.875a.404.404 0 0 0 .503.266.395.395 0 0 0 .272-.492L3.057 9.069c.025-.033.046-.056.068-.08.505-.56 2.055-1.504 4.03-1.721.651-.072.997-.023.997-.023s-.788.039-1.456.259l.138.46c.069.227.18.338.428.424.35.123.809.194 1.323.194 1.333 0 3.045-.482 4.25-1.816.168-.184.205-.376.123-.642zm-4.622.59c-1.004 0-1.908.095-2.712.286-.503.12-1.418.595-2.746 1.426L.992 2.228c.44-.055 1.372-1.105 2.524-1.464C4.9.332 6.506.588 6.688.628l1.648 6.086zm-.741 1.29c.204-.036.483-.112.721-.245.149-.082.264-.179.352-.272l.014-.001.188.624a4.463 4.463 0 0 1-1.275-.105zM7.459 1.87c.252.018.864-.068 1.835-.258.566-.188 1.12-.48 1.538-.862l.006-.006.572 1.892c.11.446.499 1.706 1.164 3.78-.658.502-1.165.846-1.522 1.03-.319.164-.867.347-1.645.55L7.459 1.87z"></path></svg>
                </HtmlLabel>
                
                {/* 比赛结束的小旗子 */}
                <HtmlLabel
                  anchorLineStroke="rgba(255,255,255,0)"
                  containerStyle={{
                    width: '20px',
                    height: '20px'
                  }}
                  x={status===GAME_IN || status===GAME_START ? startPosition.x + computeWidth2({width, time:timeCode, status, data:datax})+10 : status===GAME_END_PENDING || status===GAME_END ? computeWidth2({width, time:timeCode, status, data:datax})+10 : 10+annotationPosition.x+computeWidth2({width, time:timeCode, status, data:datax})}
                  y={20}
                >
                  <svg viewBox="0 0 13 14" width="20" height="20"><path opacity="0.8" fill="#f4d56f" fillRule="evenodd" d="M12.958 6.124 11.281.58c-.047-.161-.1-.344-.283-.394-.185-.05-.325.077-.487.225-.375.342-1.437 1.117-3.209.967l-.24-.79L7.06.58C6.992.372 6.933.192 6.607.134A8.291 8.291 0 0 0 5.161 0C3.439 0 2.029.577.859 1.838l-.067-.222a.406.406 0 0 0-.503-.266.394.394 0 0 0-.272.492l3.609 11.875a.404.404 0 0 0 .503.266.395.395 0 0 0 .272-.492L3.057 9.069c.025-.033.046-.056.068-.08.505-.56 2.055-1.504 4.03-1.721.651-.072.997-.023.997-.023s-.788.039-1.456.259l.138.46c.069.227.18.338.428.424.35.123.809.194 1.323.194 1.333 0 3.045-.482 4.25-1.816.168-.184.205-.376.123-.642zM4.536.498l.6 1.936.013-.002a8.497 8.497 0 0 0-1.214.246 4.318 4.318 0 0 0-1.015.435l.01-.007-.593-1.872A5.48 5.48 0 0 1 4.536.498zM2.282 6.52l-.706-2.323a6.095 6.095 0 0 1 1.339-1.082l.763 2.378h.001c-.717.352-1.149.738-1.397 1.027zm3.596-1.698.646 2.08a8.335 8.335 0 0 0-2.18.657l-.657-2.07c.267-.13.572-.257.922-.37a7.392 7.392 0 0 1 1.3-.305l-.75-2.384c.969-.12 1.655-.02 1.987.053l.74 2.436c-.383-.11-1.064-.228-2.008-.097zm1.717 3.182c.204-.037.483-.113.721-.246.149-.082.264-.179.352-.272l.014-.001.188.624a4.463 4.463 0 0 1-1.275-.105zM9.73 5.977c-.485.114-.772.127-1 .105L8.048 3.83c.378.027.813.021 1.28-.097.22-.055.419-.117.6-.184l-.591-1.94a4.138 4.138 0 0 0 1.496-.86c.002 0 .004-.003.006-.005l.572 1.892c-.24.251-.63.595-1.514.926l.66 2.143a4.71 4.71 0 0 1-.825.272zm2.752.484a4.85 4.85 0 0 1-1.353 1.047l-.547-1.8c.79-.351 1.226-.758 1.448-1.022l.475 1.57c.041.134.02.158-.023.205z"></path></svg>
                </HtmlLabel>
              </>)}

              {/* 折线 */}
              <LinePath
                stroke={greens[2]}
                strokeWidth={2}
                data={datax}
                x={(d) => xScale(getDate(d)) ?? 0}
                y={(d) => yScale(getStockValue(d)) ?? 0}
              />

              {/* 比赛开始价格标签 */}
              {(status!==GAME_END_PENDING || (status===GAME_END_PENDING&&timeCode>settleSeconds-4000)) && (<>
                {status!==ANTE_IN && status!==ANTE_START && status!==GAME_START_BEFORE && <HtmlLabel
                  anchorLineStroke="rgba(255,255,255,0)"
                  containerStyle={{
                    width: '20px',
                    height: '20px'
                  }}
                  x={60}
                  y={startPosition.y-10}
                >
                  <div className={styles['live-btc-box']}>
                    <div className={styles['btc-text']}>START BTCCOIN</div>
                    <div className={styles['btc-price']}><span>{toFixed(datax[findStart(datax)].price)}</span></div>
                  </div>
              </HtmlLabel>}
              </>)}
              
              {/* 格线 */}
              <GridRows scale={yScale} width={width-40} stroke='rgba(255,255,255,0.25)' height={height} numTicks={5} />

              {/* 右边btc价格区间标注 */}
              <AxisRight left={width-25} hideAxisLine hideTicks scale={yScale} numTicks={5} tickLabelProps={{
                fill: 'rgba(255,255,255,1)',
                textAnchor: 'end',
                dy: '0.33em',
              }} />

              {/* btc实时价格 */}
              <HtmlLabel
                anchorLineStroke="rgba(255,255,255,0)"
                containerStyle={{
                  width: '80px',
                }}
                x={width-5}
                y={annotationPosition.y}
              >
                <div className={styles['live-btc-box']}>
                  <div className={styles['btc-text']}>LIVE BTCCOIN</div>
                  <div className={styles['btc-price']}><span>{toFixed(datax[datax.length-1].price)}</span></div>
                </div>
              </HtmlLabel>

              {/* 折线结尾的原点 */}
              <CircleSubject className={styles['circle']} stroke={greens[2]} fill={greens[2]} radius={2} />
            </AnnotationComponent>
          </svg>
          {loading && <Loading />}
        </div>
      )}
    </ExampleControls>
  );
}
