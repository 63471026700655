import styles from './index.module.scss'
import {toFixed} from '../../utils'

const PointCard = ({showTitle=false, point=0}) => {

  return (
    <div className={styles["point-card-container"]}>
      {showTitle && <div className={styles['point-card-text']}>Obtain total revenue</div>}
      <div className={styles['card-point']}>{ toFixed(point, 2) }</div>
    </div>
  )
}

export default PointCard;
