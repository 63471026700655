import moment from 'moment'

import { LEVEL_TYPE } from '../constants'

export const formatDate = (date) => {
  return moment(date).format('YYYY.MM.DD HH:mm');
}

export const tomorrow = (date) => {
  return moment(date).add(1, 'days').format();
}

export const formatDate2 = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss.SSS");
}


export const toFixed = (str, fixed = 2) => {
  const arr = (str + '').split('.');
  if (fixed === 0) {
    return arr[0];
  }
  if (arr.length < 2) {
    const padEnd = ''.padEnd(fixed, '0');
    return `${arr[0]}.${padEnd}`;
  }
  if (arr[1].length < fixed) {
    const padEnd = arr[1].padEnd(fixed, '0');
    return `${arr[0]}.${padEnd}`;
  }
  return `${arr[0]}.${arr[1].slice(0, fixed)}`;
};

export const wait = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      // console.log('waiting ' + ms);
      resolve(ms);
    }, ms);
  });
};

export const formatWalletAddress = (str, _left = 5, _right = 3) => {
  if (str === '' || !str) {
    return '';
  }
  return (
    str.toString().substring(0, _left) +
    '...' +
    str.toString().substring(str.length - _right, str.length)
  );
};

// 判断是否是平台币和W平台币币对
export const isNWToken = (fromPlatform, toPlatform) => {
  return (fromPlatform === 1 && toPlatform === 3) || (fromPlatform === 3 && toPlatform === 1)
}

export const enterPhp = (value = 0, price = 0, phpRate) => {
  return formatPhp((value || 0) * price, phpRate);
};
export const formatPhp = (amount, phpRate = 56.7) => {
  // console.log("amount", toFixed(amount * phpRate));
  const _amount = toFixed(amount * phpRate);
  return _amount;
};


export function ceil(num = '', byte){
  let n = Math.pow(10, byte)
  return Math.ceil(num * n) / n
}

export function isNumber(value) {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

export const setMultiple = (number) => {
  if (number>=200) {
    return 3
  }
  return parseFloat(toFixed((number/100),1)) + 1
}

export const getOne = (level) => {
  if (level < 30) {
    return LEVEL_TYPE[level]
  }
  return LEVEL_TYPE[30]
}

export const setStorage = (name, value) => {
  localStorage.setItem(name, value);
}

export const getStorage = (name) => {
  return localStorage.getItem(name);
}

export const removeStorage = (name) => {
  localStorage.removeItem(name);
}

export function buildArray(n, m) {
  let result = [];
  for (let i = 0; i < n; i++) {
    result.push(i < m ? 1 : 0);
  }
  return result;
}