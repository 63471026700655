import { DisplayGate, SDKProvider } from '@tma.js/sdk-react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { TEST_ENV, ENV } from './constants'
import CONFIG from './config';
import App from "./App"

const { TG } = ENV

const Err = ({ error }) => {
  return (
    <div>
      <p>An error occurred while initializing the SDK</p>
      <blockquote>
        <code>
          {error instanceof Error
            ? error.message
            : JSON.stringify(error)}
        </code>
      </blockquote>
    </div>
  );
};

const Loading = () => {
  return (
    <div>Application is loading</div>
  );
};

const Root = () => {

  return (
    <>
      {TEST_ENV=== TG ? <TonConnectUIProvider manifestUrl="https://noton.derbygames.link/tonconnect-manifest.json">
        <SDKProvider options={{ complete: true }}>
          <DisplayGate error={Err} loading={Loading} initial={Loading}>
            <App />
          </DisplayGate>
        </SDKProvider>
      </TonConnectUIProvider> : <App />}
    </>
  );
};

export default Root
