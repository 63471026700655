import styles from './index.module.scss'
import IMG_4 from '../../assets/images/img_4.png'
import IMG_1 from '../../assets/images/img_1.png'


const EarnModal = ({number = '', close}) => {
  const handleClose = () => {
    close()
  }
  return (
    <div className={styles["earn-container"]}>
      <div className={styles["earn-text"]}>
        <img className={styles["img2"]} src={IMG_4} alt="img" />
        Earn NOT!
      </div>
      <div className={styles["text2"]}>New user rewards you</div>
      <div className={styles["earn-box"]}>
        <img className={styles["img"]} src={IMG_1} alt="img" />
        <div className={styles["number"]}>+{number}</div>
      </div>
      
      <div onClick={handleClose} className={styles['btn']}>OK</div>
    </div>
  )
}

export default EarnModal;
