/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import Cookies from 'js-cookie'
// import { message } from 'antd';
import CONFIG from '../config'
import { TOKEN } from '../constants'

const { BASE_URL } = CONFIG

// const [messageApi, contextHolder] = message.useMessage();

export const getIp = async () => {
  const res = await axios.get("https://api.ipify.org?format=json")
  if (res && res.data && res.data.ip) {
    return res.data.ip
  }
  return ''
}

axios.defaults.baseURL = BASE_URL

// 请求超时时间
axios.defaults.timeout = 30000

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json'
// 请求拦截器
axios.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.error(error)
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          console.log('no login')
          break
          // 403 token过期
          // 登录过期对用户进行提示
          // 清除本地token和清空vuex中token对象
          // 跳转登录页面
        case 403:
          console.log('token expired')
          break
          // 404请求不存在
        case 404:
          console.log('not found')
          break
          // 其他错误，直接抛出错误提示
        default:
          console.log('Network Error')
      }
      return Promise.reject(error.response)
    }
  }
)

// const { store } = rootStore

export function get(url, params) {
  const _token = localStorage.getItem(TOKEN);
  const header = _token && _token!=='undefined' ? {
    'x-token': _token
  } : {}
  return new Promise((resolve, reject) => {
    axios.get(url, {
        params: params,
        headers: header
      })
      .then(async res => {
        // if (res.data.code && (res.data.code === -1001 || res.data.code === -1011 || res.data.code === -5101 || res.data.code === -1015 || res.data.code === -1014 || res.data.code === -1016 || res.data.code === -1)) {
        //   message.error('Token Error')
        //   Cookies.remove(ASSETS_TOKEN)
        //   Cookies.remove(UUID)
        //   store.dispatch(userSlice.actions.logout())
        //   // window.location.href = '/home'
        //   // await store.dispatch('updateLoginStatus', false)
        //   // Message({
        //   //   message: res.data.Msg,
        //   //   type: 'error'
        //   // })
        // }
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function post(url, data) {
  const _token = localStorage.getItem(TOKEN);
  const header = _token && _token!=='undefined' ? {
    'x-token': _token,
    'Content-Type': 'application/json',
  } : {
    'Content-Type': 'application/json'
  }
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
        headers: header
      })
      .then(async res => {
        // if (res.data.code && (res.data.code === -1)) {
        //   message.error('Token Error')
        //   Cookies.remove(ASSETS_TOKEN)
        //   Cookies.remove(UUID)
        //   store.dispatch(userSlice.actions.logout())
        //   window.location.href = '/home'
        //   // await store.dispatch('updateLoginStatus', false)
        //   // Message({
        //   //   message: res.data.Msg,
        //   //   type: 'error'
        //   // })
        // }
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}


export const postNoToken = (url, data) => {
  console.log('data======', data)

  const header = {
    'Content-Type': 'application/json'
  }

  return new Promise((resolve, reject) => {

    axios.post(url, data, {
      headers: header
    })
    .then(async res => {
      resolve(res.data)
    })
    .catch(err => {
      reject(err.data)
    })
  });

};