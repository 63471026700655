import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useUtils } from '@tma.js/sdk-react';
import copy from 'copy-to-clipboard';
import { createInviteLink } from '../../utils/api'
import Tab from '../../layout/Tab'
import COPY from '../../assets/images/a/invite_copy.png'
import INVITE_BTN from '../../assets/images/a/invite_btn_icon2.png'
import MainButton from '../../components/MainButton'
import PointCard from '../../components/PointCard'
import styles from './index.module.scss'
import Link from "../../components/Link"
import CONFIG from "../../config"
import MyToast from '../../components/MyToast';
import {useNavigate} from 'react-router-dom'
import { on } from '@tma.js/sdk';

const { MINIAPP_LINK } = CONFIG

const Invite = () => {

  const [inviteLink, setInviteLink] = useState('')

  const navigate = useNavigate()
  const utils = useUtils();

  const {
    user
  } = useSelector((state) => state.user);

  useEffect(() => {
    const removeBack =  on('back_button_pressed', () => {
      console.log('invite')
      navigate(-10000)
    });
    getUrl(user.tgId)
    return () => {
      removeBack()
    }
  }, [])

  // TODO
  const getUrl = async (id='') => {
    const res = await createInviteLink(id)
    if (res.code !== 0) {
      return
    }
    setInviteLink(`${MINIAPP_LINK}?startapp=${res.data}`)
  }


  const handleShare = () => {
    window.gtag('event', 'click_share_btn', {
      'sharebtn': 'share'
    })
    utils.openTelegramLink(`https://t.me/share/url?url=${inviteLink}`)
  }

  const handleCopy = () => {
    copy(inviteLink);
    MyToast({
      content: 'Copy success!',
      position: 'top',
    })
  }
  return (
    <div className={styles["invite-container"]}>
      <Link className={styles['invite-detail-btn']} to="/invite-detail">
        <img src={INVITE_BTN} alt="icon" />
      </Link>
      <div className={styles['invite-text1']}>Invite your friends to earn more NOT</div>

      <div className={styles['invite-part2']}>
        <div className={styles['part-item']}>
          <div className={styles['part-icon1']}>1</div>
          <div className={styles['part-text']}>Invite friends</div>
          <div className={styles['part-point']}>+10 NOT</div>
        </div>
        <div className={styles['part-item']}>
          <div className={styles['part-icon2']}>2</div>
          <div className={styles['part-text']}>Play game to LV2</div>
          <div className={styles['part-point']}>+50 NOT</div>
        </div>
        <div className={styles['part-item']}>
          <div className={styles['part-icon3']}>3</div>
          <div className={styles['part-text']}>Shared benefits</div>
          <div className={styles['part-point']}>+50% NOT</div>
        </div>
      </div>
      <div className={styles['invite-explain']}>
        <div>1: Get 10 NOT for each friend you invite</div>
        <div>2: Get 50 NOT when your friend reaches level 2</div>
        <div>3: You will get 50% of the benefits from your friend's game</div>
      </div>
      <div onClick={handleCopy} className={styles['copy-box']}>
        <div className={styles['copy-link']}>{inviteLink}</div>
        <img src={COPY} alt="icon" />
      </div>
      <div className={styles['share-btn']}>
        <MainButton onClick={handleShare} padding='16px 0' style={{fontSize: '17px', flex: '1'}}>Share</MainButton>
      </div>
      <PointCard showTitle={true} point={user.invitationReward || 0} />
      <Tab type='4' />
    </div>
  )
}

export default Invite;
