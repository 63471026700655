import React, { useState, useEffect } from 'react'
import { useUtils } from '@tma.js/sdk-react';
import { toFixed } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import styles from './index.module.scss'
import { login, userInfo, isJoin, getGameInfo, depositStars } from '../../utils/gameApi'
import Cookies from "js-cookie"; 
import { TOKEN, TEST_ENV, ENV, testInitData, LEN, USER_OBJ, UP_OR_DOWN_LIST, USER_TYPE } from '../../constants'
import { userSlice } from "../../redux/user/slice";
import { useInitDataRaw } from '@tma.js/sdk-react';
import CountUp from 'react-countup';
import { useInterval } from 'usehooks-ts'
import MyToast from '../../components/MyToast'
import Link from "../../components/Link"
import logoIcon from "../../assets/images/logo_icon.png"
import chartIcon from "../../assets/images/chart_icon.svg"
import ADD_ICON from "../../assets/images/add_icon.png"
import menuIcon from "../../assets/images/menu_icon.svg"
import Loading from '../../components/Loading';
import NextModal from '../../components/NextModal';

const { WEB, TG } = ENV

const Header = () => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const utils = useUtils();

  // TEST | PROD
  const initDataRaw = useInitDataRaw()

  // DEV
  // const initDataRaw = testInitData

  const {
    amount,
    user,
    rank,
    times
  } = useSelector((state) => state.user);

  useEffect(() => {
    loginAndGetUserInfo()
  }, [])

  useInterval(() => {
    // if (!isLogin) {
    //   return
    // }
    loginAndGetUserInfo()
  }, 3000)
  
  const dispatch = useDispatch();
  const loginAndGetUserInfo = async () => {
    getTime()
    getUserInfo()
  }

  const handlePay = async () => {
    setLoading(true)
    const res = await depositStars()
    if (res.code !== 0) {
      setLoading(false)
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }
    const { link_url } = res.data
    utils.openTelegramLink(link_url)
    setLoading(false)
    console.log(res)
  }
  

  // wake userId 1499574699
  const userLogin = async (inviteId) => {
    const res = await login({
      init_data: initDataRaw,
      url_data: inviteId
    })

    if (res.code == -1004) {
      dispatch(
        userSlice.actions.setRejectIP({
          rejectIP: true
        })
      )
      return
    } else {
      dispatch(
        userSlice.actions.setRejectIP({
          rejectIP: false
        })
      )
    }

    if (res.code !== 0) {
      Cookies.remove(TOKEN);
      MyToast({
        content: 'Login failed, please try to re-enter the game',
        position: 'top',
        duration: 6000
      })
      dispatch(
        userSlice.actions.setAmount({
          amount: 0
        })
      )
      dispatch(
        userSlice.actions.setUpPool({
          upPool: 0,
        })
      );
      dispatch(
        userSlice.actions.setDownPool({
          downPool: 0,
        })
      );
      dispatch(
        userSlice.actions.setUpList({
          upList: UP_OR_DOWN_LIST,
        })
      );
      dispatch(
        userSlice.actions.setDownList({
          downList: UP_OR_DOWN_LIST,
        })
      );
      return
    }
    Cookies.set(TOKEN, res.data.token);
    const { user_type, balance } = res.data.user
    if (user_type === USER_TYPE.New && balance<0.1) {
      dispatch(
        userSlice.actions.setBetAmount({
          betAmount: 0,
        })
      );
    }
    dispatch(
      userSlice.actions.setLogin({
        status: true
      })
    );
    loginAndGetUserInfo()
  }

  // 获取各个状态时间
  const getTime = async () => {
    const res = await getGameInfo()
    if (res.code !== 0) {
      return
    }
    const { gaming_seconds, settle_seconds, staking_seconds, wait_start_seconds, cur_room_id } = res.data
    dispatch(
      userSlice.actions.setTime({
        time: {
          gamingSeconds: gaming_seconds,
          settleSeconds: settle_seconds,
          stakingSeconds: staking_seconds,
          waitStartSeconds: wait_start_seconds
        },
      })
    );
  }

  const getUserInfo = async () => {
    const res = await isJoin();

    if (res.code !== 0) {
      return
    }
    const { rank } = res.data
    const { Score, times } = res.data.team
    const { status } = res.data.tournament
    if (status !== 2) {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
    dispatch(
      userSlice.actions.setAmount({
        amount: Score
      })
    )
    dispatch(
      userSlice.actions.setRank({
        rank: rank
      })
    )
    dispatch(
      userSlice.actions.setTimes({
        times: times
      })
    )
  }

  return (
    <div className={styles["header-container"]}>
      {user.rwStatus===1 && <div className={styles['pending']}>Recharge in progress...</div>}
      <div className={styles["logo"]}>
        <span style={{fontSize: '12px'}}>Rank:</span> {rank}
      </div>
      <div onClick={handlePay} className={styles['amount']}>
       {/* <div className={styles['refresh']}></div> */}
        <div className={styles['text']}>
          <CountUp preserveValue decimals={1} end={toFixed(amount,1)} />
        </div>
        <div className={styles['icon-box']}>
          <img src={ADD_ICON} className={styles['wallet']} alt="wallet" />
          <div className={styles['recharge-text']}>Recharge opportunity: {parseInt(5-times)}</div>
        </div>
      </div>
      <div className={styles['more']}>
        <Link to="/ranking">
          <div className={styles['user-img']}>
            {/* <img src={user.img || USER_ICON} alt="user" /> */}
            <img src={chartIcon} alt="data" />
          </div>
        </Link>
        {/* <TonConnectButton /> */}
        {/* <Link style={{marginTop: "5px"}} to="/history">
          <img src={menuIcon} alt="more" />
        </Link> */}
      </div>
      {loading && <Loading />}
      {showModal && <NextModal />}
    </div>
  )
}

export default Header;
