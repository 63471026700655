
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import CONFIG from '../../config';

import { scaleTime, scaleLinear } from '@visx/scale';
import { extent } from '@visx/vendor/d3-array';
import { Annotation, EditableAnnotation } from '@visx/annotation';
import { userSlice } from "../../redux/user/slice";
import { GAME_STATUS, USER_TYPE } from '../../constants'
import { formatDate2 } from '../../utils'
import { winningTask } from '../../utils/gameApi'

const { GAME_START, GAME_IN, GAME_END_PENDING } = GAME_STATUS
const { BASE_WS } = CONFIG

const data = [{
  time: 1714378397842,
  status: 1,
  price: 65000
}]
const getDate = (d) => {
  return new Date(formatDate2(d.time)).valueOf()
};
const getStockValue = (d) => d.price;

const approxTooltipHeight = 70;

export default function ExampleControls({
  width,
  height,
  compact = false,
  children,
}) {

  const didUnmount = useRef(false);
  const [socketUrl] = useState(`${BASE_WS}/ws`);
  const [loading, setLoading] = useState(true)

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,
  {
    shouldReconnect: (closeEvent) => {
      /*
      useWebSocket will handle unmounting for you, but this is an example of a 
      case in which you would not want it to automatically reconnect
    */
      return didUnmount.current === false;
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const {
    status,
    timeCode,
    betStatus,
    userType,
    isLogin
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (isLogin) {
      getTask()
    }
  }, [isLogin])
  

  useEffect(() => {
    if (lastMessage) {
      updateData();
    }
  }, [lastMessage])
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      setLoading(false)
    }
  }, [readyState])

  useEffect(() => {
    const _btcStatus = btcStatus === 'up' ? 1: 2
    if (status === GAME_END_PENDING) {

      // 新人第一把结束弹首充奖励
      console.log('===========betStatus============', betStatus)
      console.log('===========userType============', userType)

      // 结束后重置押注状态 更新任务状态
      setTimeout(async () => {
        dispatch(
          userSlice.actions.setBetStatus({
            betStatus: 0,
          })
        );
        getTask()
      }, 3000)
      
    }


  },[status])

  const getTask = async () => {
    const res = await winningTask()
    if (res.code !== 0) {
      return
    }
    const {type, data} = res.data

    dispatch(
      userSlice.actions.setTaskType({
        taskType: type,
      })
    );

    if (type === 1 && data.length>0) {
      dispatch(
        userSlice.actions.setNewTaskId({
          newTaskId: data[0].id,
        })
      );
      dispatch(
        userSlice.actions.setNewTaskInfo({
          newTaskStatus: data[0].status,
          newTaskNum: data[0].times,
        })
      );
      return
    }
    if (type === 2) {
      const _data = data.map(item => {
        return {
          id: item.id,
          times: item.times,
          status: item.status,
          award: (item.description)*2,
          description: item.description
        }
      })
      dispatch(
        userSlice.actions.setTaskList({
          taskList: _data
        })
      );
    }
  }

  const updateData = async () => {

    const {
      status,
      surplus_mill_seconds,
      prices
    } = JSON.parse(lastMessage?.data)
    sendMessage('PING')
    dispatch(
      userSlice.actions.setStatus({
        status: status,
      })
    );
    dispatch(
      userSlice.actions.setTimeCode({
        timeCode: surplus_mill_seconds,
      })
    );

    // 优化点
    if (!(prices instanceof Array)) {
      return
    }

    setDatax(prices)
  };

  const [datax, setDatax] = useState(data)
  const [btcStatus, setBtcStatus] = useState('up') // up down

  const xScale = useMemo(
    () =>
      scaleTime({
        domain: extent(datax, (d) => getDate(d)),
        range: [0, width * 0.7],
      }),
    [width, datax],
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: extent(datax, (d) => getStockValue(d)),
        range: [height - 60, 40],
      }),
    [height, datax],
  );

  useEffect(() => {
    if (status !== GAME_IN) {
      return
    }
    const startPrice = datax[findStart(datax)]
    const nowPirce = datax[datax.length - 1]
    const _btcStatus = nowPirce.price >= startPrice.price ? 'up' : 'down'
    setBtcStatus(_btcStatus)
  }, [datax])


  const [editLabelPosition, setEditLabelPosition] = useState(false);
  const [editSubjectPosition, setEditSubjectPosition] = useState(false);
  const [title, setTitle] = useState('Title');
  const [subtitle, setSubtitle] = useState(
    compact ? 'Subtitle' : 'Subtitle with deets and deets and deets and deets',
  );
  const [connectorType, setConnectorType] = useState('elbow');
  const [subjectType, setSubjectType] = useState('circle');
  const [showAnchorLine, setShowAnchorLine] = useState(true);
  const [labelType, setLabelType] = useState('svg');
  const [verticalAnchor, setVerticalAnchor] = useState(
    'auto',
  );
  const [horizontalAnchor, setHorizontalAnchor] = useState('auto');
  const [labelWidth] = useState(compact ? 100 : 175);
  const [annotationPosition, setAnnotationPosition] = useState({
    x: xScale(getDate(datax[datax.length - 1])) ?? 0,
    y: yScale(getStockValue(datax[datax.length - 1])) ?? 0,
    dx: compact ? -50 : -100,
    dy: compact ? -30 : -50,
  });

  const findStart = (arr) => {
    // const start = appleStock[(TIME1+TIME2)/200+100].close
    const ind = arr.findIndex(x => {
      return x.status === GAME_START
    })
    return (ind === -1) ? 0 : ind
  }

  const [startPosition, setStartPosition] = useState({
    x: xScale(getDate(datax[findStart(datax)])) ?? 0,
    y: yScale(getStockValue(datax[findStart(datax)])) ?? 0,
    dx: compact ? -50 : -100,
    dy: compact ? -30 : -50,
  });
  // update annotation position when scale's change
  useEffect(() => {
    setAnnotationPosition((currPosition) => ({
      ...currPosition,
      x: xScale(getDate(datax[datax.length - 1])) ?? 0,
      y: yScale(getStockValue(datax[datax.length - 1])) ?? 0,
    }));
  }, [xScale, yScale, datax]);

  useEffect(() => {

    setStartPosition((currPosition) => ({
      ...currPosition,
      x: xScale(getDate(datax[findStart(datax)])) ?? 0,
      y: yScale(getStockValue(datax[findStart(datax)])) ?? 0,
    }));
  }, [xScale, yScale, datax]);

  return (
    <>
      {children({
        AnnotationComponent:
          editLabelPosition || editSubjectPosition ? EditableAnnotation : Annotation,
        annotationPosition,
        startPosition,
        approxTooltipHeight,
        connectorType,
        datax,
        editLabelPosition,
        editSubjectPosition,
        getDate,
        getStockValue,
        horizontalAnchor: horizontalAnchor === 'auto' ? undefined : horizontalAnchor,
        labelType,
        labelWidth,
        setAnnotationPosition,
        showAnchorLine,
        subjectType,
        subtitle,
        title,
        verticalAnchor: verticalAnchor === 'auto' ? undefined : verticalAnchor,
        xScale,
        yScale,
        status,
        timeCode,
        btcStatus,
        betStatus,
        connectionStatus,
        loading
      })}
      <style>{`
        .controls {
          font-size: 13px;
          line-height: 1.5em;
        }
        .controls > div {
          margin-bottom: 4px;
        }
      `}</style>
    </>
  );
}
