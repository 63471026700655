import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { stake } from '../../../../utils/api'
import MyToast from '../../../../components/MyToast'
import { useHapticFeedback } from '@tma.js/sdk-react';

import ReactDom from 'react-dom';
import { AddComp } from './GameComp'
import Countdown from '../Countdown'
import HeaderPools from '../HeaderPoolsV2'
import { toFixed, wait, setMultiple, getOne } from '../../../../utils'
import { userSlice } from "../../../../redux/user/slice";

import SHITOU from '../../../../assets/images/a/shitou_icon.png'
import CHUIZI from '../../../../assets/images/a/chuizi_icon.png'
import CLICK_BTN from '../../../../assets/images/a/addnot_btn.png'
import { animated, useSpring } from '@react-spring/web'
import { userInfo } from '../../../../utils/api'
import STAR_ICON from "../../../../assets/images/a/star_icon.png"
import styles from './index.module.scss'
import BANNER from '../../../../assets/images/win_not_banner.png'
import mgif from '../../../../assets/images/a/R-Mgif.gif'
import Link from "../../../../components/Link"

import DropCoins from '../../../../components/DropCoins'

const ALL = 10

const CLICK_TIME = 20

let countdownInterval
let num = 0
let drop = 0

const Game = ({openAward}) => {

  const [btnStatus, setBtnStatus] = useState('on')
  const [gameStatus, setGameStatus] = useState('2') // 1 游戏中 ; 2 非游戏中
  const [dropNot, setDropNot] = useState(0)

  const hapticFeedback = useHapticFeedback()

  const [springsbig, api_big] = useSpring(() => ({
    from: { transform: 'scale(1)' },
  }))

  useEffect(()=>{

    clearInterval(countdownInterval);
    countdownInterval = undefined
    dispatch(
      userSlice.actions.setNotNumber({
        notNumber: 0,
      })
    );
    dispatch(
      userSlice.actions.setBuyNot({
        buyNot: 0,
      })
    );
    dispatch(
      userSlice.actions.setTimeCode({
        timeCode: 0,
      })
    );
  },[])

  useEffect(()=>{
    api_big.start({
      from: {
        transform: 'scale(1)'
      },
      to: {
        transform: 'scale(1.8)'
      },
      reset: true,
      reverse: true
    })
    
  },[dropNot])


  const [springs, api] = useSpring(() => ({
    from: { transform: 'rotate(0deg)' },
  }))

  const [springs2] = useSpring(() => ({
    from: { opacity: '0' },
    to: {
      opacity: '1'
    },
    // reset: true,
    loop: true,
    config: {
      mass: 2,
      tension: 100,
      friction: 30
    }
  }))

  const {
    buyNot,
    user,
    notNumber,
    timeCode
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleClickNot = async (level) => {
    const containerDiv = document.createElement('div');
    document.getElementById("not").appendChild(containerDiv);
    // 这里返回的是对该组件的引用
    ReactDom.render(<AddComp  level={level} />, containerDiv);
    await wait(1500)
    document.getElementById("not").removeChild(containerDiv);
  }

  // 下注结束后统一下注

  const submitCoins = async (number, drop) => {

    const _buyNot = number*getOne(user.level)

    const _amount = _buyNot*setMultiple(number)

    const res =  await stake({
      amount: parseFloat(_amount.toFixed(3)),
      up_or_down: 1,
      room_id: 1,
      red_packet: drop
    })
    
    console.log('res', res)

    if (res.code !== 0) {
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }

    getUserInfo()

    const showAward = localStorage.getItem('showAward');
    if(showAward === '1') {
      return
    }
    localStorage.setItem('showAward', '1');
    setTimeout(() => {
      openAward()
    }, 2000)
  }

  const countdownAndAccumulate = (durationInSeconds, callback) => {
    function accumulateNumber(type) {
      // if (type===2) {
        num++
      // }
      dispatch(
        userSlice.actions.setNotNumber({
          notNumber: notNumber+1,
        })
      );
      dispatch(
        userSlice.actions.setBuyNot({
          buyNot: buyNot+getOne(user.level),
        })
      );
    }
    if (!countdownInterval) {
      accumulateNumber(1)
    }
    
    console.log('countdownInterval', countdownInterval)
    if (countdownInterval) {
      accumulateNumber(2)
      return
    }

    let totalTime = durationInSeconds;

    dispatch(
      userSlice.actions.setTimeCode({
        timeCode: totalTime,
      })
    );
    countdownInterval = setInterval(function() {
      totalTime--;

      dispatch(
        userSlice.actions.setTimeCode({
          timeCode: totalTime,
        })
      );

      if (totalTime <= 0) {
        clearInterval(countdownInterval);
        countdownInterval = undefined
        console.log('num', num)
        callback(num, drop);
      }
    }, 1000);
  }

  const handleClickBtn = async () => {

    if (user.energy<1) {
      MyToast({
        content: 'No more energy',
        position: 'top',
      })
      return
    }
    
    if (btnStatus === 'off') {
      return
    }
    hapticFeedback.impactOccurred('medium');
    handleClickNot(user.level)
    api.start({
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(-40deg)'
      },
      reset: true,
      reverse: true
    })
    setGameStatus('1')
    countdownAndAccumulate(CLICK_TIME, async (_num, _drop)=> {
      setGameStatus('2')
      setBtnStatus('off')
      await submitCoins(_num, _drop)
      await wait(4000)
      setBtnStatus('on')
      setDropNot(0)
      drop = 0
      num = 0
      dispatch(
        userSlice.actions.setNotNumber({
          notNumber: 0,
        })
      );
      dispatch(
        userSlice.actions.setBuyNot({
          buyNot: 0,
        })
      );
      
    })
  }

  const getUserInfo = async () => {
    const res = await userInfo();
    console.log('userInfo', res.data)

    if (res.code !== 0) {
      return
    }
    console.log('res====', res)
    const { id, email, avatar, score, describe, level, level_begin, level_end, energy, tg_id, top_level, invitation_reward, unlock_score } = res.data
  
    dispatch(
      userSlice.actions.setAmount({
        amount: score
      })
    )
    dispatch(
      userSlice.actions.setUser({
        user: {
          id: id,
          tgId: tg_id,
          email: email,
          img: avatar,
          name: describe,
          point: score,
          level: level,
          levelBegin: level_begin,
          levelEnd: level_end,
          energy: energy,
          topLevel: top_level,
          invitationReward: invitation_reward,
          unlockScore: unlock_score
        }
      })
    )
  }

  const hanldleInvte = () => {

  }


  const setDropNotFn = () => {
    setDropNot(dropNot+1)
    drop++
  }

  return (
    <div className={styles['game-container']}>
      <DropCoins gameStatus={gameStatus} setDropNotFn={setDropNotFn} />
      <div className={styles['top-box']}>
        <div className={styles['first-line']}>
          <HeaderPools />
          <Link to="/vs">
            <img onClick={hanldleInvte} src={BANNER} className={styles['banner']} />
          </Link>
        </div>
        <animated.div style={{...springsbig}} className={styles['drop-not']}>{dropNot===0 ? '' : `+${dropNot} NOT`}</animated.div>
        <div className={styles['lightning-box']}>
          {user.energy} /{ALL}
        </div>
        
        <Link to="/" className={styles['box']}>
          <img src={mgif} alt="icon" />
          <div className={styles['dian']}></div>
          <div className={styles['border']}></div>
        </Link>
      </div>
      <div className={styles['countdown']}>
        <Countdown time={timeCode} btnStatus={btnStatus} dropNot={dropNot} />
      </div>
      <div className={styles['shitou']}>
        <div id='not' className={styles['add-animated']}></div>
        <img className={styles['shitou-icon']} src={SHITOU} alt="icon" />
        <animated.img onClick={handleClickBtn} style={{...springs}} className={styles['chuizi']} src={CHUIZI} alt="icon" ></animated.img>
        <animated.img style={{...springs2}} className={styles['star-icon']} src={STAR_ICON} alt="icon" ></animated.img>
      </div>
      <button className={styles['click-btn']} onClick={handleClickBtn}>
        <img src={CLICK_BTN} alt="btn" />
      </button>
    </div>
  )
}

export default Game;
