import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useUtils } from '@tma.js/sdk-react';
import { useInterval } from 'usehooks-ts'
import Link from "../../components/Link"
import LOGO from '../../assets/images/a/not_coins_2.png'
import STAR from '../../assets/images/star_icon.png'
import { tournamentInfo, isJoin, depositStars } from '../../utils/gameApi'
import styles from './index.module.scss'
import Loading from '../../components/Loading';
import MyToast from '../../components/MyToast'


const GameInfo = ({close}) => {
  const [detail, setDetail] = useState({})
  const [joined, setJoined] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    amount
  } = useSelector((state) => state.user);

  useEffect(() => {
    joins()
    getInfo()
  }, [])

  const utils = useUtils();

  useInterval(() => {
    joins()
  }, 3000)

  const getInfo = async () => {
    const res = await tournamentInfo()
    if (res.code !== 0) {
      return
    }
    setDetail(res.data)
  }

  const joins = async () => {
    const res = await isJoin()
    if (res.code !== 0) {
      return
    }
    setJoined(res.data.enter)
  }
  const handlePay = async () => {
    setLoading(true)
    const res = await depositStars()
    if (res.code !== 0) {
      setLoading(false)
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }
    const { link_url } = res.data
    utils.openTelegramLink(link_url)
    setLoading(false)
    console.log(res)
  }

  return (
    <div className={styles["gameInfo-container"]}>
      <div onClick={close} className={styles['close']}></div>
      <div className={styles['title']}>Welcome to the competition</div>
      <div className={styles['desc']}>Join in competitions to unlock your <span style={{fontSize: '16px', fontWeight: '600'}}>{amount>=10000? parseInt(amount): ''}</span> NOT</div>
      <div className={styles['info']}>
        <div className={styles['item']}>
          <div className={styles['name']}>Event Time:</div>
          <div className={styles['text']}>Monday 00:00-Sunday 23:00(UTC)</div>
        </div>
        <div className={styles['item']}>
          <div className={styles['name']}>Prize pool:</div>
          <div className={styles['text']}><img src={LOGO} alt="icon" />{detail.prize_pool}</div>
        </div>
        <div className={styles['item']}>
          <div className={styles['name']}>Participants:</div>
          <div className={styles['text']}>{detail.joined} / {detail.max_person}</div>
        </div>
        <div className={styles['item']}>
          <div className={styles['name']}>Status:</div>
          {detail.status === 2 && <div className={styles['text1']}>In progress</div>}
          {(detail.status === 1 || detail.status === 3 || detail.status === 4) && <div className={styles['text2']}>Ending</div>}
        </div>
        <div className={styles['item']}>
          <div className={styles['name']}>Entry:</div>
          <div className={styles['text']}><img src={STAR} alt="icon" />{detail.entry_fee}</div>
        </div>
      </div>
      {(joined && detail.status === 2) && <Link to="/game" className={styles['btn']}>Game</Link>}
      {(!joined && detail.status === 2) && <div onClick={handlePay} className={styles['btn']}>Join</div>}
      {(detail.status === 1 || detail.status === 3 || detail.status === 4) && <div onClick={close} className={styles['btn']}>Close</div>}
      {loading && <Loading />}
    </div>
  )
}

export default GameInfo;
