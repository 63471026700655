import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import { toFixed, setMultiple } from '../../../../utils'
import CountUp from 'react-countup'
import UP_ICON from '../../../../assets/images/a/up_icon.png'
import { animated, useSpring } from '@react-spring/web'

import styles from './index.module.scss'

const HeaderPoolsV2 = () => {
  const {
    buyNot,
    notNumber
  } = useSelector((state) => state.user);

  const [springs, api] = useSpring(() => ({
    from: { transform: 'scale(1)' },
  }))

  const [springs2, api2] = useSpring(() => ({
    from: { transform: 'scale(1)' },
  }))

  useEffect(()=> {
    api.start({
      from: {
        // fontSize: '28px'
        transform: 'scale(1)'
      },
      to: {
        // fontSize: '40px'
        transform: 'scale(1.8)'
      },
      reset: true,
      reverse: true
    })
  },[buyNot])


  useEffect(()=> {
    api2.start({
      from: {
        // fontSize: '28px'
        transform: 'scale(1)'
      },
      to: {
        // fontSize: '40px'
        transform: 'scale(3)'
      },
      reset: true,
      reverse: true
    })
  },[setMultiple(notNumber)])

  return (
    <div className={styles["headerpools-container"]}>
      <div className={styles['left']}>
        <div className={styles['text']}>Mining NOT</div>
        <div className={styles['detail']}>
          
          {/* <CountUp preserveValue decimals={buyNot==0?0:3} end={buyNot} className={styles['points']} /> */}
          <animated.div style={{...springs}} className={styles['points']} >{toFixed(buyNot, buyNot==0?0:3)}</animated.div>
          <img src={UP_ICON} alt="icon" />
          <animated.div style={{color:notNumber<10? '#cccccc':'#FFC701', ...springs2}} className={styles['up-text']}>
            x <span style={{transform: `scale(${setMultiple(notNumber)})`}}>{setMultiple(notNumber)}</span>
          </animated.div>
        </div>
      </div>
    </div>
  )
}

export default HeaderPoolsV2;
