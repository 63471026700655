import styles from './index.module.scss'
import { TonConnectButton } from '@tonconnect/ui-react';
import { CloseCircleOutline } from 'antd-mobile-icons'

const PopupV2 = ({visible, onClose}) => {
  return (
    <div style={{display: visible ? 'flex' : 'none'}} className={styles["popup-v2-container"]}>
      <div className={styles["popup-body"]}>
        <div className={styles['popup-box']}>
          <div className={styles['popup-close']} onClick={onClose}>
            <CloseCircleOutline fontSize={32} color='#cccccc' />
          </div>
          <div className={styles['popup-title']}>Connected wallet</div>
          <div className={styles['popup-text']}>Need to connect wallet to pay</div>
          <TonConnectButton className={styles['ton-btn']} />
        </div>
      </div>
    </div>
  )
}

export default PopupV2;
