import { useState, useEffect } from 'react'
import LICKY_TEXT from '../../assets/images/lucky_text.png'
import FIRST_BOX from '../../assets/images/first_box.png'
import FIRST_TEXT from '../../assets/images/first_text.png'
import FIRST_IMAGE from '../../assets/images/first_image.png'
import Link from '../../components/Link'
import styles from './index.module.scss'
import { bonus } from '../../utils/gameApi'
import MyToast from '../MyToast'


const FirstAward = ({close}) => {
  const [step, setStep] = useState('1')
  const handleOpen = async () => {
    const res = await bonus()
    if (res.code !== 0) {
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }
    setStep('2')
  }

  return (
    <div className={styles["first-container"]}>
      <div onClick={close} className={styles['close']}></div>
      {step==='1' && <>
        <img className={styles["image1"]} src={LICKY_TEXT} alt="icon" />
        <img className={styles["image2"]} src={FIRST_BOX} alt="icon" />
        <div onClick={handleOpen} className={styles["btn"]}>Open</div>
      </>}
      {step==='2' && <>
        <img className={styles["image3"]} src={FIRST_TEXT} alt="icon" />
        <div className={styles["image-box"]}>
          <img className={styles["image4"]} src={FIRST_IMAGE} alt="icon" />
          <div className={styles["award"]}>+100000</div>
        </div>
        <div className={styles["text"]} >Participate in the competition and obtain Exchange limit!</div>
        <Link to="/vs" className={styles["btn"]}>Go</Link>
      </>}
      
    </div>
  )
}

export default FirstAward;
