import { useEffect, useState } from "react";
import { useSelector , useDispatch } from "react-redux";
import { userSlice } from "../../../../redux/user/slice";
import { buildArray } from "../../../../utils"
import MyToast from "../../../../components/MyToast"

import DONE from '../../../../assets/images/task_done.png'
import UNDO from '../../../../assets/images/task_undo.png'
import NEW_BOX from '../../../../assets/images/new_box.png'

import styles from './index.module.scss'

const defaultArr = buildArray(10, 0)

const NewWin = () => {

  const [list, setList] = useState(defaultArr)

  const {
    newTaskStatus,
    newTaskNum,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    setList(buildArray(10, newTaskNum))
  }, [])

  useEffect(() => {
    setList(buildArray(10, newTaskNum))
  }, [newTaskNum])

  const handleOpenModal = () => {

    if (newTaskStatus!==3) {
      MyToast({
        content: 'Task not completed',
        position: 'top',
      })
      return
    }
    dispatch(
      userSlice.actions.setNewTask({
        newTask: true
      })
    )
  }
  return (
    <div className={styles["newwin-container"]}>
      <div className={styles["task-title"]}>Accumulated 10 wins</div>
      <div onClick={handleOpenModal} className={styles["task-info"]}>
        <div className={styles["list"]}>
          <div className={styles['bar']}></div>
          {list.map((x, index) => {
            return (<img key={index} src={x===1 ? DONE : UNDO} alt="icon" />)
          })}
          
        </div>
        <div className={styles["new-box"]}>
          {newTaskStatus===3 && <div className={styles["dian"]}></div>}
          <img src={NEW_BOX} alt="box" />
        </div>
      </div>
    </div>
  )
}

export default NewWin;
