import React from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import Example from './Example';

import styles from './index.module.scss'

const ChartV2 = () => {
  return (
    <ParentSize>{({ width, height }) => <Example width={width} height={height} />}</ParentSize>
  )
}

export default ChartV2;