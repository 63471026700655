import { userSlice } from './user/slice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { actionLog } from './middlewares/actionLog'

import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: "root",
  storage: storageSession
}


const rootReducer = combineReducers({
  user: userSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const store = createStore(rootReducer, applyMiddleware(thunk))
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({serializableCheck: false}),
    actionLog
  ],
  devTools: true,
})

const persistor = persistStore(store)

export default { store, persistor }
