import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import styles from './index.module.scss'
import LINE_2 from '../../assets/images/a/line_2_icon.png'
import LOGO from '../../assets/images/a/not_coins_2.png'
import { history, rank } from '../../utils/gameApi'
import Loading from '../../components/Loading';
import {formatDate} from '../../utils'
const History = () => {

  const [rankList, setRankList] = useState([])
  const [loading, setLoading] = useState(false)
  const [self, setSelf] = useState({
    ranking: '',
    name: '',
    point: 0,
    reward: 0
  })
  const [time, setTime] = useState({
    begin: '',
    end: ''
  })

  useEffect(() => {
    getHistory()
  }, [])

  const {
    user
  } = useSelector((state) => state.user);

  const getHistory = async () => {
    setLoading(true)
    const res = await history()
    if (res.code !== 0 || res.data.length ===0) {
      setLoading(false)
      return
    }
    const {id, begin_time, end_time} = res.data[0]
    setTime({
      begin: formatDate(begin_time*1000),
      end: formatDate(end_time*1000)
    })
    const _rank = await rank(id)
    if (_rank.code !== 0) {
      setLoading(false)
      return
    }
    setRankList(_rank.data)
    setLoading(false)
    const _self = _rank.data.find(x => {
      return x.user_id === user.id
    })
    if (_self) {
      setSelf({
        ranking: _self.rank,
        name: _self.username,
        point: _self.Score,
        reward: _self.reward
      })
    }
  }

  return (
    <div className={styles["ranking-container"]}>
      <div className={styles["line-box"]}>
        <img src={LINE_2} alt="" />
        <div className={styles['text']}>Previous ranking <br/>({time.begin} - {time.end})</div>
      </div>
      <div className={styles['ranking-box']}>
        <div className={styles['title-box']}>
          <div className={styles['title-item']}>ranking</div>
          <div className={styles['title-item']}>name</div>
          <div className={styles['title-item']}>integral</div>
          <div className={styles['title-item']}>obtain</div>
        </div>
        {rankList.map((item, index) => {
          return (<div key={item.id} className={styles['info-box']}>
            <div className={styles['info-item']}>{index+1}</div>
            <div className={styles['info-item']}>{item.username}</div>
            <div className={styles['info-item']}>{item.Score}</div>
            <div className={styles['info-item']}>
              <img src={LOGO} alt="icon" />
              {item.reward}
            </div>
          </div>)
        })}
        {rankList.length === 0 && <div className={styles['no-data']}>No data</div>}
        {self.ranking && <div className={styles['self']}>
          <div className={styles['info-item']}>{self.ranking}</div>
          <div className={styles['info-item']}>{self.name}</div>
          <div className={styles['info-item']}>{self.point}</div>
          <div className={styles['info-item']}>
            <img src={LOGO} alt="icon" />
            {self.reward}
          </div>
        </div>}
      </div>
      {loading && <Loading />}
    </div>
  )
}

export default History;
