import { post, get, postNoToken } from './http';
// 获取token
export const getToken = async data => {
  try {
    const res = await post('/user/sign_login', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
  
}

// chain list
export const chainList = async () => {
  try {
    const res = get('/token/chain_list')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}


// 注册 暂时不用
export const getRegister = async () => {
  try {
    const res = get('/user/register')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 登录 进入游戏调登录 如果有 后端直接返回用户信息 如果没有 后端注册后返回登录信息
export const login = async (data) => {
  try {
    const res = postNoToken('/user/login', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 获取用户基本信息
export const userInfo = async () => {
  try {
    const res = get('/user/info')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 获取游戏基本信息 场次id 各个区间时间等
export const getGameInfo = async () => {
  try {
    const res = get('/game/info')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 获取游戏参加游戏人信息
export const getStakeUsers = async (roomId) => {
  try {
    // const res = post(`/game/stake-users`, {room_id: roomId})

    // test
    const res = post(`/game/stake-users`, {room_id: roomId})
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 下注
export const stake = async (data) => {
  try {
    const res = post('/game/stake', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 充值
export const deposit = async (data) => {
  try {
    const res = post('/game/deposit', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 提现
export const withdraw = async (data) => {
  try {
    const res = post('/game/withdraw', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 个人历史记录
export const stakeHistory = async (page = 1, pageSize = 100) => {
  try {
    const res = post('/user/stake-history', {
      page: page,
      page_size: pageSize
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 昨日排行
export const yesterdayRanking = async () => {
  try {
    const res = post('/game/yesterday-ranking')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// TON 报价
export const tonPrice = async () => {
  try {
    const res = get('/game/ton-price')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 个人积分明细
export const scoreHistory = async () => {
  try {
    const res = post('/user/score-history')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 积分排行榜
export const scoreRanking = async (page=1, pageSize=100) => {
  try {
    const res = post('/game/score-ranking',{
      page: page,
      page_size: pageSize
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 任务列表
export const winningTask = async () => {
  try {
    const res = get('/game/winning-task')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 领取新手任务奖励
export const taskNewReward = async (id) => {
  try {
    const res = post('/game/task-reward', {
      user_task_id: id
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 进阶奖励
export const taskReward = async (id) => {
  try {
    const res = post('/game/task-reward', {
      user_task_id: id
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 1 usdt对应多少币
export const usdtCoin = async () => {
  try {
    const res = get('/user/usdt-coin')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 创建支付订单
export const createPayOrder = async (amount, chainId, currency) => {
  try {
    const res = post('/user/wallet-order', {
      amount: amount,
      chain_id: chainId,
      currency: currency
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 获取订单详情
export const getOrderDetail = async (orderId) => {
  try {
    const res = post('/user/wallet-order-no', {
      order_no: orderId
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 领取奖金
export const bonus = async () => {
  try {
    const res = post('/game/bonus')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 下注 2
export const stakeV2 = async (data) => {
  try {
    const res = post('/game/stake_fair', data)
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 锦标赛详情
export const tournamentInfo = async () => {
  try {
    const res = get('/tournament/info')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 排名
export const rank = async (id) => {
  try {
    const res = post('/tournament/rank', {
      tournament_id: id,
      page: 1,
      page_size: 1000
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 历史
export const history = async (id) => {
  try {
    const res = post('/tournament/history', {
      page: 1,
      page_size: 10
    })
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 是否参加了锦标赛
export const isJoin = async () => {
  try {
    const res = get('/tournament/team-user')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}

// 星星支付
export const depositStars = async () => {
  try {
    const res = post('/game/deposit-stars')
    return res
  } catch (error) {
    return {
      code: -1
    }
  }
}