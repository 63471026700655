import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Tab from '../../layout/Tab'
import PointCard from '../../components/PointCardV2'
import styles from './index.module.scss'
import {useNavigate} from 'react-router-dom'
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { on } from '@tma.js/sdk';
import WALLET_ICON from '../../assets/images/a/claim_icon.png'
import TG_LOGO from '../../assets/images/a/tg_logo.png'
import { saveAddress, userInfo } from '../../utils/api'
import { userSlice } from "../../redux/user/slice";
import NOTON_IMG from "../../assets/images/a/claim_noton.png"
import LOGO from '../../assets/images/a/not_coins_2.png'
import STAR from '../../assets/images/star_icon.png'
import { tournamentInfo, withdraw } from '../../utils/gameApi'
import GameInfo from "../../components/GameInfo"
import { Modal } from 'antd-mobile'
import WithdrawModal from './WithdrawModal'
import MyToast from '../../components/MyToast';
import { isNumber } from '../../utils'
import SuccessModal from '../../components/SuccessModal';

let intervalId;

const Airdrop = () => {
  const [withdrawVisible, setWithdrawVisible] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [show, setShow] = useState(false)
  const [detail, setDetail] = useState({})
  const [time, setTime] = useState('00:00')
  const [withdrawInput, setWithdrawInput] = useState('')

  const navigate = useNavigate()

  const {
    amount,
    user
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const _userAddress = 'UQBdKgWcQYYpqdK3nvU11Gs3y8l3y-5ITxbVMiMHStlPcx7e';

  // DEV
  // const userFriendlyAddress = _userAddress

  const userFriendlyAddress = useTonAddress()

  useEffect(() => {
    getInfo()
  }, [])

  useEffect(() => {
    const removeBack =  on('back_button_pressed', () => {
      console.log('airdrop')
      navigate(-10000)
    });
    return () => {
      removeBack()
    }
  }, [])


  useEffect(() => {
    if (userFriendlyAddress) {
      getUserInfo(userFriendlyAddress)
    }
  }, [userFriendlyAddress])

  const getInfo = async () => {
    const res = await tournamentInfo()
    if (res.code !== 0) {
      return
    }
    setDetail(res.data)
    
    const {id, remain_time} = res.data
    countdown(remain_time)
  }

  const handleCancel = () => {
    setWithdrawVisible(false)
  }

  const handleWithdow = () => {
    if (!userFriendlyAddress) {
      MyToast({
        content: 'Please connect your wallet first',
        position: 'top',
      })
      return
    }
    setWithdrawVisible(true)
  }

  const handleSuccessClose = () => {
    setShowSuccess(false)
  }

  const handleConfirm = async () => {
    console.log('Withdrow')
    if (!withdrawInput) {
      MyToast({
        content: 'value failed!',
        position: 'top',
      })
      return
    }
    if (withdrawInput<10) {
      MyToast({
        content: 'value failed!',
        position: 'top',
      })
      return
    }
    const res = await withdraw({
      wallet_address: userFriendlyAddress,
      amount: withdrawInput,
      token_address: 'EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT'
    })
    if (res.code !== 0) {
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }
    setWithdrawVisible(false)
    setShowSuccess(true)
    
  }

  const countdown = (seconds) => {
    // const hours = Math.floor(seconds / 3600);
    intervalId = setInterval(() => {
      var days = Math.floor(seconds / (3600*24));
      const _day = seconds % (3600*24);
  
      var hrs = Math.floor(_day / 3600);
      const _hrs = _day % 3600;
  
      var mins = Math.floor(_hrs / 60);
      const _mins = _hrs % 60;
  
      // 补零处理
      hrs = hrs < 10 ? '0' + hrs : hrs;
      mins = mins < 10 ? '0' + mins : mins;
      const s = _mins < 10 ? '0' + _mins : _mins;
      const text = days>0 ? days + " days " + hrs + ":" + mins + ":" + s : hrs + ":" + mins + ":" + s
      if (seconds > 0) {
        setTime(text);
        seconds--;
      } else {
        setTime('00:00');
        clearInterval(intervalId);
      }
    }, 1000);
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  const getUserInfo = async () => {
    await saveAddress(userFriendlyAddress)
    const res = await userInfo();
    console.log('userInfo', res.data)

    if (res.code !== 0) {
      return
    }
    console.log('res====', res)
    const { id, email, avatar, score, describe, level, level_begin, level_end, energy, tg_id, top_level, invitation_reward, unlock_score } = res.data
    dispatch(
      userSlice.actions.setAmount({
        amount: score
      })
    )
    dispatch(
      userSlice.actions.setUser({
        user: {
          id: id,
          tgId: tg_id,
          email: email,
          img: avatar,
          name: describe,
          point: score,
          level: level,
          levelBegin: level_begin,
          levelEnd: level_end,
          energy: energy,
          topLevel: top_level,
          invitationReward: invitation_reward,
          unlockScore: unlock_score
        }
      })
    )
  }


  return (
    <div className={styles["airdrop-container"]}>
      {/* <div className={styles['airdrop-banner']}>
        <img className={styles['wallet-icon']} src={WALLET_ICON} alt="icon" />
        <div className={styles['airdrop-title']}>NOT COIN</div>
        <div className={styles['airdrop-text']}>Coming soon...<br/>
This system will be released in Oct.<br/>
You can claim your $NOT by doing a few simple tasks</div>
      </div> */}
      {/* 连接钱包 */}
      {!userFriendlyAddress ? (<div className={styles['wallet-container']}>
        <div className={styles['ton-logo']}>TON</div>
        <img src={TG_LOGO} alt="logo" className={styles['tg-logo']} />
        <div className={styles['point']}>+100</div>
        <div className={styles['text1']}>Connect TON Wallet</div>
        <div className={styles['text2']}>Save your earnings</div>
        <TonConnectButton />
        {/* <div className={styles['desc']}>Do the highest task Unlock 70% claim</div> */}
      </div>) : (<div className={styles['locked']}>
        <TonConnectButton />
      </div>)}
      {/* <div className={styles['noton-img']}>
        <img src={NOTON_IMG} alt="image" />
      </div> */}
      <div className={styles['token-title']}>Locked Account</div>
      <PointCard type={1} point={amount} />
      <div style={{marginTop: '24px'}} className={styles['token-title']}>Unlock Account</div>
      <PointCard withdraw={handleWithdow} type={2} point={user.unlockScore} />
      {/* <div className={styles['task-list']}>
        <div>Tasks list</div>
      </div> */}
      <div className={styles['vs-box']}>
        <div className={styles['title']}>Go to unlock</div>
        <div onClick={handleShow} className={styles['detail']}>
          <div className={styles['bg']}></div>
          <div className={styles['info']}>
            <div className={styles['item']}>
              <div className={styles['item-title']}>Prize pool up to</div>
              <div className={styles['item-num']}>
                <img src={LOGO} alt="icon" />
                {detail.prize_pool}
              </div>
            </div>
            <div className={styles['item']}>
              <div className={styles['item-title']}>Entry fee</div>
              <div className={styles['item-num']}>
                <img src={STAR} alt="icon" />
                {detail.entry_fee}
              </div>
            </div>
            <div className={styles['item']}>
              <div className={styles['item-title']}>Time left</div>
              <div className={styles['item-num']}>{time}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        bodyStyle={{
          backgroundColor: "#000000",
          border: '1px solid #FF7C1D'
        }}
        visible={withdrawVisible}
        content={<WithdrawModal
          close={handleCancel}
          withdraw={handleConfirm}
          value={withdrawInput}
          onChange={val => {
            if (!isNumber(val) && val!=='') {
              return
            }
            setWithdrawInput(val)
          }}
        />}
      />
      {showSuccess && <SuccessModal close={handleSuccessClose} />}
      {show && <GameInfo close={handleClose} />}
      <Tab type='5' />
    </div>
  )
}

export default Airdrop;
