import { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import styles from './index.module.scss'


const NextModal = () => {

  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1)
  }
  return (
    <div className={styles["next-container"]}>
      <div className={styles["next-text"]}>
        The current game is over, please join the next one
      </div>
      <div onClick={handleBack} className={styles['btn']}>Go Back</div>
    </div>
  )
}

export default NextModal;
