import { createSlice } from '@reduxjs/toolkit'
import { USER_OBJ, UP_OR_DOWN_LIST, GAME_STATUS, USER_TYPE } from '../../constants'
const initialState = {
  isLogin: false,
  address: '',
  loading: false,
  status: GAME_STATUS.ANTE_START,
  timeCode: 0,
  btcPrices: null,
  amount: 0, // 账户余额
  upPool: 0, // up总金额
  downPool: 0, // down总金额
  betAmount: 200, // 选择注码
  betStatus: 0, // 0 未下注 1: up 2: down
  upList: UP_OR_DOWN_LIST,
  downList: UP_OR_DOWN_LIST,
  upSelf: USER_OBJ,
  downSelf: USER_OBJ,
  showStart: true,
  showFirstDeposit: false,
  rejectIP: false,
  userType: USER_TYPE.Un_known,
  user: {
    id: 0,
    tgId: '',
    name: '',
    img:'',
    email: '',
    point: 0,
    level: 1,
    levelBegin: 0,
    levelEnd: 0,
    energy: 0,
    topLevel: 0,
    invitationReward: 0,
    unlockScore: 0,
    honor: 0,
    honorLevel: 1
  },
  audioStatus: 0,
  statusTime: {
    gamingSeconds: 20000,
    settleSeconds: 10000,
    stakingSeconds: 25000,
    waitStartSeconds: 3000
  },
  buyTon: 0, // 每局总压住
  buyNot: 0, // 每局总压住
  oneTon: 0, // 每次点击总压住
  oneNot: 0, // 每次点击总压住
  tonNumber: 0, // 每局点击数
  notNumber: 0, // 每局点击数
  odds: 2.5,
  showSpring: true,
  showDot: true,
  rank: '',
  times: 0
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRank: (state, action) => {
      const { rank } = action.payload || {}
      state.rank = rank
    },
    setTimes: (state, action) => {
      const { times } = action.payload || {}
      state.times = times
    },
    setShowDot: (state, action) => {
      const { showDot } = action.payload || {}
      state.showDot = showDot
    },
    setTonNumber: (state, action) => {
      const { tonNumber } = action.payload || {}
      state.tonNumber = tonNumber
    },
    setNotNumber: (state, action) => {
      const { notNumber } = action.payload || {}
      state.notNumber = notNumber
    },
    setShowSpring: (state, action) => {
      const { showSpring } = action.payload || {}
      state.showSpring = showSpring
    },
    setOneTon: (state, action) => {
      const { oneTon } = action.payload || {}
      state.oneTon = oneTon
    },
    setOneNot: (state, action) => {
      const { oneNot } = action.payload || {}
      state.oneNot = oneNot
    },
    setOdds: (state, action) => {
      const { odds } = action.payload || {}
      state.odds = odds
    },
    setBuyNot: (state, action) => {
      const { buyNot } = action.payload || {}
      state.buyNot = buyNot
    },
    setBuyTon: (state, action) => {
      const { buyTon } = action.payload || {}
      state.buyTon = buyTon
    },
    setShowFirstDeposit: (state, action) => {
      const { showFirstDeposit } = action.payload || {}
      state.showFirstDeposit = showFirstDeposit
    },
    setUserType: (state, action) => {
      const { userType } = action.payload || {}
      state.userType = userType
    },
    setRejectIP: (state, action) => {
      const { rejectIP } = action.payload || {}
      state.rejectIP = rejectIP
    },
    setStart: (state, action) => {
      const { start } = action.payload || {}
      state.showStart = start
    },
    setUpSelf: (state, action) => {
      const { upSelf } = action.payload || {}
      state.upSelf = upSelf
    },
    setDownSelf: (state, action) => {
      const { downSelf } = action.payload || {}
      state.downSelf = downSelf
    },
    setTime: (state, action) => {
      const { time } = action.payload || {}
      state.statusTime = time
    },
    setLoading: (state, action) => {
      const { loading } = action.payload || {}
      state.loading = loading
    },
    setLogin: (state, action) => {
      const { status } = action.payload || {}
      state.isLogin = status
    },
    setAddress: (state, action) => {
      const { address } = action.payload || {}
      state.address = address
    },
    setStatus: (state, action) => {
      const { status } = action.payload || {}
      state.status = status
    },
    setPrices: (state, action) => {
      const { btcPrices } = action.payload || {}
      state.btcPrices = btcPrices
    },
    setTimeCode: (state, action) => {
      const { timeCode } = action.payload || {}
      state.timeCode = timeCode
    },

    setAmount: (state, action) => {
      const { amount } = action.payload || {}
      state.amount = amount
    },
    setUpPool: (state, action) => {
      const { upPool } = action.payload || {}
      state.upPool = upPool
    },
    setBetAmount: (state, action) => {
      const { betAmount } = action.payload || {}
      state.betAmount = betAmount
    },

    setDownPool: (state, action) => {
      const { downPool } = action.payload || {}
      state.downPool = downPool
    },
    setBetStatus: (state, action) => {
      const { betStatus } = action.payload || {}
      state.betStatus = betStatus
    },
    setUpList: (state, action) => {
      const { upList } = action.payload || {}
      state.upList = upList
    },
    setDownList: (state, action) => {
      const { downList } = action.payload || {}
      state.downList = downList
    },
    setUser: (state, action) => {
      const { user } = action.payload || {}
      state.user = user
    },
    setAudioStatus: (state, action) => {
      const { audioStatus } = action.payload || {}
      state.audioStatus = audioStatus
    },
  },
})