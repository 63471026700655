
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { userSlice } from "../../redux/user/slice";
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import styles from "./index.module.scss";
import { toFixed, formatWalletAddress, isNumber } from "../../utils";
import { deposit, withdraw, tonPrice } from '../../utils/api'
import { Modal } from 'antd-mobile'
import copy from 'copy-to-clipboard';
import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'
import SuccessModal from '../../components/SuccessModal';
import CONFIG from '../../config';
import MyToast from '../../components/MyToast';

import { TON_WEI, TEST_ENV, ENV } from '../../constants'

const { DEPOSIT_ADDRESS } = CONFIG
const { TG } = ENV

const Wallet = () => {

  const [withdrawVisible, setWithdrawVisible] = useState(false)
  const [depositVisible, setDepositVisible] = useState(false)
  const [withdrawInput, setWithdrawInput] = useState('10')
  const [depositValue, setDepositValue] = useState('100')
  const [ton, setTon] = useState(6)
  const [loadingIcon, setLoadingIcon] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const _userAddress = 'UQB7edof6rDLH-BKRT45wobUYxHQ8HL0tWvb-J12DaqVMLcK';

  // TEST | PROD WEB
  // const userFriendlyAddress = TEST_ENV === TG ? useTonAddress() : _userAddress;
  const userFriendlyAddress = useTonAddress()

  // DEV
  // const userFriendlyAddress = _userAddress

  const {
    amount
  } = useSelector((state) => state.user);

  // 付款
  const [tonConnectUI, setOptions] = useTonConnectUI();

  useEffect(() => {
    getTonPrice()
  }, [])

  const getTonPrice = async () => {
    setLoadingIcon(true)
    const res = await tonPrice()
    console.log('getTonPrice =====', res)
    if (res.code !== 0) {
      setLoadingIcon(false)
      return
    }
    if (isNumber(res.data)) {
      setTon(res.data)
    }
    setLoadingIcon(false)
  }

  const handleDepositConfim = async () => {
    console.log('depositValue', depositValue)
    if (!depositValue) {
      MyToast({
        content: 'Please enter the amount',
        position: 'top',
      })
      return
    }
    if (depositValue<10) {
      MyToast({
        content: 'Minimum recharge of 10 gamecoin',
        position: 'top',
      })
      return
    }

    const res = await deposit({
      wallet_address: userFriendlyAddress,
      amount: depositValue,
      chain_id: 0,
      token_address: ''
    })
    if (res.code !== 0) {
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }
    const { ton_amount } = res.data
    const price = toFixed(TON_WEI*ton_amount, 0)
    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 6000,
      messages: [
        {
          address: DEPOSIT_ADDRESS, // destination address
          amount: price //Toncoin in nanotons
        }
      ]
    }
    
    tonConnectUI.sendTransaction(transaction)
    
    MyToast({
      content: res.message,
      position: 'top',
    })
    handleDepositCancel()
  }

  const selectAmount = (amount) => {
    console.log('amount=====', amount)
    setDepositValue(amount)
  }

  const handleDepositCancel = () => {
    setDepositVisible(false)
  }

  const handleDepoist = () => {
    setDepositVisible(true)
  }

  const handleCancel = () => {
    setWithdrawVisible(false)
  }

  const handleWithdow = () => {
    setWithdrawVisible(true)
  }

  const handleSuccessClose = () => {
    setShowSuccess(false)
  }

  const handleConfirm = async () => {
    console.log('Withdrow')
    if (!withdrawInput) {
      MyToast({
        content: 'value failed!',
        position: 'top',
      })
      return
    }
    if (withdrawInput<10) {
      MyToast({
        content: 'value failed!',
        position: 'top',
      })
      return
    }
    const res = await withdraw({
      wallet_address: userFriendlyAddress,
      amount: withdrawInput,
    })
    if (res.code !== 0) {
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }
    setWithdrawVisible(false)
    setShowSuccess(true)
    
  }

  const handleCopy = () => {
    copy(userFriendlyAddress);
    MyToast({
      content: 'Copy success!',
      position: 'top',
    })
  }
  
  return (
    <div className={styles["wallet-container"]}>
      <div className={styles["wallet"]}>
        <div className={styles['header']}>
          <div className={styles['top']}>
            {/* <div onClick={goBack} className={styles['back']}></div> */}
            {userFriendlyAddress && TEST_ENV===TG && <TonConnectButton />}
          </div>
          <div className={styles['title-box']}>
            <div className={styles['title']}></div>
          </div>
        </div>
        <div className={styles['info-box']}>
          {!userFriendlyAddress&&
          <div className={styles['no-login']}>
            <div className={styles['wallet-bg']}></div>
            <div className={styles['hint']}>
              In order to see your in-game assets,<br/>
              you will need to connect your wallet. 
            </div>
            { TEST_ENV===TG &&<TonConnectButton />}
          </div>}
          {userFriendlyAddress && <>
            <div className={styles['address-box']}>
              <div className={styles['text']}>Your wallet address:</div>
              <div className={styles['address']}>
                <div className={styles['address-text']}>
                  {formatWalletAddress(userFriendlyAddress, 18, 10) || '/'}
                </div>
                <div onClick={handleCopy} className={styles['copy']}></div>
              </div>
            </div>
            <div className={styles['coin-balance-box']}>
              <div className={styles['text']}>Coin Balance</div>
              <div className={styles['amount']}>
                {toFixed(amount,1)}
              </div>
            </div>
          </>}
        </div>
        {userFriendlyAddress && <div className={styles['btn-box']}>
          <div onClick={handleDepoist} className={styles['deposit']}>
            Deposit
          </div>
          <div onClick={handleWithdow} className={styles['withdraw-box']}>
            <div className={styles['withdraw-bg']}>
              <div className={styles['withdraw-text']}>Withdraw</div>
            </div>
          </div>
        </div>}
        <Modal
          visible={depositVisible}
          bodyStyle={{
            backgroundColor: "#000000",
            border: '1px solid #FF7C1D'
          }}
          content={<DepositModal
            loadingIcon={loadingIcon}
            getTonPrice={getTonPrice}
            selectAmount={selectAmount}
            ton={ton}
            close={handleDepositCancel}
            deposit={handleDepositConfim}
            value={depositValue}
            onChange={val => {
              setDepositValue(val)
            }}
          />}
        />
        <Modal
          bodyStyle={{
            backgroundColor: "#000000",
            border: '1px solid #FF7C1D'
          }}
          visible={withdrawVisible}
          content={<WithdrawModal
            close={handleCancel}
            withdraw={handleConfirm}
            value={withdrawInput}
            onChange={val => {
              setWithdrawInput(val)
            }}
          />}
        />
      </div>
      {showSuccess && <SuccessModal close={handleSuccessClose} />}
    </div>
  );
}

export default Wallet;