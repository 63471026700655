import { wait } from "../../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { userSlice } from "../../../../redux/user/slice";
import styles from './index.module.scss'
import { GAME_STATUS, USER_TYPE } from '../../../../constants'
import { stakeV2, getGameInfo } from '../../../../utils/gameApi'
import MyToast from '../../../../components/MyToast'

const { ANTE_START, ANTE_IN, GAME_START_BEFORE, GAME_START, GAME_IN, GAME_END, GAME_END_PENDING } = GAME_STATUS

const BetButton = () => {

  const {
    status,
    amount,
    upPool,
    downPool,
    betAmount,
    betStatus,
    upList,
    downList,
    user,
    userType
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleBet = async (bets) => {
    if (status!==ANTE_IN) {
      MyToast({
        content: 'The next game will coming soon',
        position: 'top',
      })
      return
    }
    if(betStatus!==0) {
      MyToast({
        content: 'You have already placed a bet',
        position: 'top',
      })
      return
    }
    if (betAmount>amount) {
      MyToast({
        content: 'Insufficient balance, Please go to the wallet to recharge',
        position: 'top',
      })
      return
    }

    // if (betAmount<= 0 && userType !== USER_TYPE.New) {
    //   MyToast({
    //     content: 'Please select the bet amount',
    //     position: 'top',
    //   })
    //   return
    // }

    const gameRes =  await getGameInfo()
    const { cur_room_id } = gameRes.data

    const res =  await stakeV2({
      amount: betAmount,
      up_or_down: bets,
      room_id: cur_room_id
    })

    if (res.code !== 0) {
      MyToast({
        content: res.message,
        position: 'top',
      })
      return
    }

    if (bets === 1) {
      MyToast({
        content: 'Successful bet up',
        position: 'top',
      })
      dispatch(
        userSlice.actions.setBetStatus({
          betStatus: 1,
        })
      );
      return
    }
    if (bets === 0) {
      MyToast({
        content: 'Successful bet down',
        position: 'top',
      })
      dispatch(
        userSlice.actions.setBetStatus({
          betStatus: 2,
        })
      );
      return
    }
    return
    
  }

  return (
    <div className={`${styles["betbutton-container"]} step5`}>
      <button onClick={() => handleBet(1)} className={`${styles['up']} ${status!==ANTE_IN&&styles['up-disabled']}`}>
        <div className={styles['get-text']}>Get</div>
        <div className={styles['text2']}>Up!</div>
      </button>
      <button onClick={() => handleBet(0)} className={`${styles['down']} ${status!==ANTE_IN&&styles['down-disabled']}`}>
        <div className={styles['get-text']}>Get</div>
        <div className={styles['text2']}>Down!</div>
      </button>
    </div>
  )
}

export default BetButton;
