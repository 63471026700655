import styles from './index.module.scss'
import IMG_2 from '../../assets/images/img_2.png'


const StartModal = ({close}) => {
  const handleClose = () => {
    close()
  }
  return (
    <div className={styles["start-container"]}>
      <div className={styles["start-text"]}>
        Let's start earning NOT now
      </div>
      <div className={styles["text2"]}>Accumulate 50 NOT and withdraw immediately！</div>
      <img onClick={handleClose} className={styles["img"]} src={IMG_2} alt="img" />
      
    </div>
  )
}

export default StartModal;
