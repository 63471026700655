import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Root from './Root';
import { Provider } from 'react-redux'
// import store from './store';
import rootStore from './redux/store'
import { AliveScope } from 'react-activation'
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={rootStore.store}>
    <AliveScope>
      <PersistGate persistor={rootStore.persistor}>
        <Root />
      </PersistGate>
    </AliveScope>
  </Provider>
);