import { Toast } from 'antd-mobile';

import styles from './index.module.scss';

const MyToast = ({ ...props }) => {

  return Toast.show({
    maskClassName: `${styles["my-toast"]}`,
    position: 'center',
    duration: 3000,
    ...props,
  })
}

export default MyToast;