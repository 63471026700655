import { useState } from 'react'
import Link from "../../components/Link"
import SUCCESS_IMG from "../../assets/images/a/success_icon.svg"

import styles from './index.module.scss'

const SuccessModal = ({close}) => {

  return (
    <div className={styles['modal-container']}>
      <div className={styles['content']}>
        <div onClick={close} className={styles['close']}></div>
        <img src={SUCCESS_IMG} alt="icon"  />
        <div className={styles['text']}>Withdrawal successful</div>
        <div className={styles['text2']}>It will take some time for the money to arrive, please be patient.</div>
        {/* <Link to="/wallet"> */}
        <div onClick={close} className={styles['goback-btn']}>Go check the money</div>
        {/* </Link> */}
      </div>
    </div>
  )
}

export default SuccessModal;
