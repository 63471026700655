import { useEffect, useMemo, Suspense } from "react";
import {
  createNavigator,
  useBackButtonIntegration,
  useNavigatorIntegration,
} from '@tma.js/react-router-integration';
import { useBackButton, useClosingBehavior, usePostEvent } from '@tma.js/sdk-react';
import {
  Navigate,
  Route,
  Router,
  Routes,
} from 'react-router-dom';

import routes from './router'

import Loading from "./components/Loading"

const App = () => {

  const closingBehaviour = useClosingBehavior()
  const postEvent = usePostEvent()
  
  useEffect(() => {
    closingBehaviour.enableConfirmation();
    postEvent('web_app_expand', { is_visible: false })
  }, []);

  const tmaNavigator = useMemo(createNavigator, []);
  const [location, navigator] = useNavigatorIntegration(tmaNavigator);
  const backButton = useBackButton();

  useBackButtonIntegration(tmaNavigator, backButton);

  return (
    <Suspense fallback={<Loading />}>
      <Router location={location} navigator={navigator}>
        <Routes>
          {routes.map((route) => <Route key={route.path} {...route} />)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App
